/* CodeEditor.css */
body {
	background: linear-gradient(180deg, #D3E2FF 0%, #D3E2FF 100%);
}
button, a, link, select, input {
    cursor:pointer;
}
input:focus, select:focus {
    outline: none!important;
}
.codeEditor .whiteLogo {
    display: none !important;
}

.codeEditor {
	height: 100vh;
}

.codeEditor header {
    height: 45px;
}
.codeEditor header .logo img {
    height: 16px;
}
.codeEditor header ul li a {
    font-size: 14px;
}
.codeEditor header .header-right-actions button, .codeEditor header .header-right-actions li a {
    font-size: 14px;
    color:#00152d;
}

.codeEditor-container {
	display: flex;
    flex-direction: row;
    height:calc(100% - 45px);
    width:100vw;
}

/* Left Sidebar */
.cEditLeftSidebar {
	border-radius:10px;
	background-color:#ffffff;
	margin:10px;
	padding:0;
	width:220px;
	margin:0 0 10px 10px!important;
	min-width: 220px;
}
.cEditLeftSidebar .LeftSideBarTopControls {
	background-color: #F1F1F1;
    height: 35px;
    border-bottom: 1px solid #CECECE;
    display: flex;
    align-items: center;
    border-radius:5px 5px 0 0;
}
.DragHandler {
	margin: 11px;
    display: flex;
    align-items: center;
}
.cEditLeftSidebar .LeftSideBarTopControls button {
	border: none;
    padding: 0 15px 0 0;
    height: 100%;
    font-weight:700;
    font-size:12px;
    color:#767676;
}
.cEditLeftSidebar .LeftSideBarTopControls button:hover {
    color:#3e3e3e;
}
.cEditLeftSidebar .LeftSideBarTopControls button.active {
	color:#00152D;
}
.sidebar-collapse-wrapper {
    border: none;
    border-radius: 0;
}
.sidebar-collapse-wrapper .ant-collapse-item {
    border-bottom: none;
}
.sidebar-collapse-wrapper .ant-collapse-item .ant-collapse-header {
    padding: 0 10px;
    margin: 0;
    height: 34px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2ecff;
}
.sidebar-collapse-wrapper .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon {
    padding-inline-end: 5px;
}
.sidebar-collapse-wrapper .ant-collapse-item .ant-collapse-header .ant-collapse-header-text {
    color: #00152d;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    padding: 0;
}
.sidebar-collapse-wrapper .ant-collapse-content {
    border-top:none;
}
.sidebar-collapse-wrapper .ant-collapse-content .ant-collapse-content-box {
    padding:0;
    margin:0;
}
.sidebar-collapse-wrapper .ant-collapse-content .ant-collapse-content-box ul li {
    align-items: center;
    border-bottom: 1px solid #e2ecff;
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: flex-start;
    padding: 2px 5px 2px 10px;
    width: 100%;
    border-block-end: none;
}
.sidebar-collapse-wrapper .ant-collapse-content .ant-collapse-content-box ul .ant-list-item.active .ant-list-item-meta .ant-list-item-meta-title {
    font-weight:700;
}
.sidebar-collapse-wrapper .ant-collapse-content .ant-collapse-content-box ul .ant-list-item.active {
    background-color: #e2ecff;
}
.sidebar-collapse-wrapper .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
    color: #00152d;
    font-size: 12px;
    margin:0;
    font-weight:400;
}
.codeUserComponents .ant-list-item-action {
    display: flex;
    flex-direction: row;
    column-gap: 0px;
    margin: 0;
    padding: 0;
}
.codeUserComponents .ant-list-item-action li {
    min-width: 30px;
    margin: 0;
    padding: 0;
    height: 30px;
    display: flex;
    justify-content: center;
}
.codeUserComponents .ant-list-item-action li .ant-list-item-action-split {
    display: none;
}

/* Pages Sidebar */
.files-sidebar {
	display: flex;
    height: 100%;
    padding: 0;
    width: 220px;
    flex-direction: column;
}
.files-sidebar-controls {
    padding: 0;
    display: flex;
    height: 28px;
    border-bottom: 1px solid #cecece;
    flex-direction: row;
}
.files-sidebar-controls input{
    width: 100%;
    font-size: 12px;
    letter-spacing: -0.115px;
    line-height: 12px;
    text-overflow: ellipsis;
    border-radius: 0;
    border: none;
    color:#00152d;
    border-right: 1px solid #e5e3e3;
    padding: 0 0px 0 10px;
    background: #f1f1f175;
}
.files-sidebar-controls button {
    height: 28px;
    border-radius: 0;
    border: none;
    background: #f1f1f1;
    color:#00152d;
    font-size: 12px;
    font-weight: 400;
    padding: 0 10px;
}
.files-sidebar h4 {
	font-size:12px;
	font-weight:700;
	color:#00152D;
	margin:0;
	padding:10px;
}
.files-sidebar ul { 
	list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    border-bottom: 1px solid #e2ecff;
}
.files-sidebar ul li { 
	align-items: center;
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: flex-start;
    padding: 2px 5px 2px 10px;
    border-top: 1px solid #e2ecff;
    color: #00152d;
    font-size: 12px;
    width: 100%;
}
.files-sidebar ul li:hover { 
    background-color: #E2ECFF50;
}
.files-sidebar ul li.active { 
	background-color: #E2ECFF;
    font-weight:700;
}


/* Code Mirror Editor */
.CodeEditorMain {
	border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 0 10px 10px;
    overflow: hidden;
    padding: 0;
    width: 100%;
    background: #fff;
    flex-grow: 1;
}
.CodeMirrorContainer {
	border-radius: 0 0 10px 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.CodeEditorMain .CodeMirrorContainer .MonacoEditorContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}
.CodeEditorMain .CodeMirrorContainer .MonacoEditorContainer.has-content {
    height: 100%; 
}
.CodeEditorMain .CodeMirrorContainer .MonacoEditorContainer.monacoEditorInnerDiv {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
}
.CodeEditorMain .CodeMirrorContainer .MonacoEditorContainer.monacoEditorInnerDiv section {
    
}
.CodeEditorMain .CodeMirrorContainer .MonacoEditorContainer .monaco-editor {
    min-height: 18px !important;
}


/* CodeEditor top menu */
.code-editor-top-bar {
	background: #F1F1F1;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 10px 10px 0 0;
}
.code-editor-top-bar ul {
	line-height: 35px;
    align-items: center;
    background-color: #f1f1f1;
    border-bottom: 1px solid #cecece;
    border-radius: 5px 5px 0 0;
    display: flex;
    height: 35px;
}
.code-editor-top-bar ul li div span {
	font-size: 12px;
    font-weight: 700;
    color: #767676;
}
.code-editor-top-bar ul li:hover div span {
	color: #00152d;
}

/* Right Sidebar Styling */
.cEditRightSidebar {
    margin: 0 10px 10px 0 !important;
    display: flex;
    flex-direction: column;
    width: 233px;
    min-width: 233px;
    flex-grow: 1;
}
.cEditRightSidebar .RightSideBarTopControls {
    background-color: #F1F1F1;
    height: 35px;
    border-bottom: 1px solid #CECECE;
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0 0;
}
.cEditRightSidebar .RightSideBarTopControls button {
    border: none;
    padding: 0 15px 0 0;
    height: 100%;
    font-weight: 700;
    font-size: 12px;
    color: #00152D;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.cEditRightSidebar .RightSideBarTopControls button.temp-disabled {
    color: #767676;
}
.cEditRightSidebar .RightSideBarTopControls button:hover {
    color: #3e3e3e;
}
.cEditRightSidebar .RightSideBarTopControls button.active {
    color: #00152D;
}
.CodeRightSidebar {
	height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0;
    width: 100%;
    background: #f1f1f1;
    display: flex;
    flex-direction: column;
    height: calc(100% - 36px);
}
.ai-intro {
	display: flex;
    flex-direction: row;
    padding: 10px 10px 0 10px;
    align-items: center;
}
.ai-intro h4 {
	font-size:15px;
	font-weight:700;
	color:#00152D;
	margin:0;
	padding:10px;
	width:100%;
}
.CodeRightSidebarContents {
	margin:0;
	padding:10px;
	height: calc(100% - 65px);
    display: flex;
    flex-direction: column;
}
.premade-prompt {
    padding: 10px;
    border: 1px solid rgb(204, 204, 204);
    cursor: pointer;
    border-radius: 5px;
    margin: 10px;
    background: #0061d1;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.promptHeading {
    justify-content: space-between;
    align-items: center;
    display: flex;
    color: #fff;
}
.premade-prompt .promptHeading h4 {
    font-size: 15px;
    margin: 0;
    color: #fff;
}

.premade-prompt p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #ffffff;
}
.ai-response {
	flex-grow: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
}
.ai-prompt {
    min-height: 140px;
    display: flex;
    flex-direction: column;
}
.ai-prompt textarea {
    min-height:98px;
    max-height:400px;
}
.ai-response-contents {
    width: 100%;
    height: calc(100% - 50px);
}
.ai-response-contents h4 {
    color: #00152d;
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0 0 0;
    padding: 0;
    width: 100%;
}
.ai-response-contents pre {
    width:100%;
}
.ai-response-contents pre p {
    width:100%;
}


