/* frontend/src/components/homepage/HomeGridLines/HomeGridLines.css */
@media (min-width: 300px) {

  .home-grid-lines {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 15px;
    width: calc(100% - 30px);
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    pointer-events: none;
    z-index: 100;
  }

  .home-grid-lines .column {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  .home-grid-lines .keyline {
    position: absolute;
    width: 1px;
    height: 100vh;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0.15;
  }

  .home-grid-lines .keyline.right-edge {
    right: 0;
  }

  /*.home-grid-lines .animated-keyline {
    position: absolute;
    width: 1px;
    height: 60px;
    background: #ffffff; 
    top: 0;
    opacity: 1;
    filter: drop-shadow(0 0 6px rgba(255, 255, 0, 1)); 
  }

  @keyframes animateKeyline {
    0% {
      opacity: 1;
      top: 0;
    }
    100% {
      opacity: 1;
      top: 100vh;
    }
  }*/

}

@media (min-width: 600px) {

}

@media (min-width: 768px) {

}

@media (min-width: 1024px) {

  .home-grid-lines {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 40px;
    width: calc(100% - 80px);
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    pointer-events: none;
    z-index: 100;
  }

  .home-grid-lines .column {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  .home-grid-lines .keyline {
    position: absolute;
    width: 1px;
    height: 100vh;
    background: rgba(255, 255, 255, 0.5);
  }

  .home-grid-lines .keyline.right-edge {
    right: 0;
  }

  /*.home-grid-lines .animated-keyline {
    position: absolute;
    width: 1px;
    height: 60px;
    background: #ffffff; 
    top: 0;
    opacity: 1;
    filter: drop-shadow(0 0 6px rgba(255, 255, 0, 1)); 
  }*/

  /*@keyframes animateKeyline {
    0% {
      opacity: 1;
      top: 0;
    }
    100% {
      opacity: 1;
      top: 100vh; 
    }
  }*/

}

@media (min-width: 1280px) {

}

@media (min-width: 1600px) {

}

