/* Header styles */

@media (min-width: 300px) {
  .logo {
    margin-left:15px;
  }

  .header-links,
  .header-right-actions {
    display: none!important;
  }

  .hamburger {
    display: flex;
    position: relative;
    cursor: pointer;
    width: 30px;
    height: 25px;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    margin: 0 15px 0 0;
  }

  .hamburger span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #333;
    margin-bottom: 0;
    transition: all 0.3s ease;
    padding:0;
  }

  /* Fullscreen Menu */
  .fullscreen-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
  }

  .fullscreen-menu.show {
    transform: translateY(0);
  }

  .fullscreen-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .fullscreen-menu ul li {
    margin: 20px 0;
  }

  .fullscreen-menu ul li a,
  .fullscreen-menu ul li button {
    font-size: 24px;
    color: white;
    text-decoration: none;
    border: none;
    background: none;
    cursor: pointer;
  }

  .fullscreen-menu ul li button:hover,
  .fullscreen-menu ul li a:hover {
    text-decoration: underline;
  }

  /* Close Icon */
  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 36px;
    color: white;
    cursor: pointer;
  }

  .close-icon:hover {
    color: #ccc;
  }

  .logInModal {
  }
  .logInModal .ant-modal-title {
    font-size:18px;
  }
  .logInModal .ant-modal-content {
    padding: 5px 15px 15px 15px;
  }
  .logInModal h2 {
    font-size: 18px;
    margin: 0 0 15px 0;
  }
  .logInModal .LoginModalForm {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .logInModal .LoginModalForm input {
    padding: 8px 10px;
  }
  .logInModal .LoginModalForm button {
    height: 40px;
    background-color: #006FF1;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
  }
}





@media (min-width: 768px) {

}

@media (min-width: 1024px) {
  .hamburger {
    display: none;
  }

  .fullscreen-menu {
    display: none;
  }
  .header-links,
  .header-right-actions {
    display: flex!important;
  }
}


@media (min-width: 1280) {

}


@media (min-width: 1440px) {

}


@media (min-width: 1920px) {

}