/* frontend/src/components/homepage/HomeSection4/HomeSection4.css */
@media (min-width: 300px) {

  .home-section-4 {
    padding: 0px 15px;
    background: linear-gradient(180deg, #101010 0%, #000 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  .home-section-4 .homeHeadingH2 {
    text-align: left;
    opacity: 0;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    margin:0;
  }

  .images-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
  }

  .image {
    height: auto;
    border-radius: 10px;
  }

  .home-section-4 .image1 {
    margin: 0 10% 30px 10%;
    width: 80%;
    opacity: 0;
  }
  .image2withText {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
    margin: 0 10%;
    width: 80%;
  }
  .home-section-4 .image2 {
    width: 100%;
    height:auto; 
    opacity: 0;
  }

  .home-section-4 .text-container {
    width: 100%;
    margin:0;
    margin-top:0;
    opacity: 0;
  }

  .text-container p {
    -webkit-text-fill-color: #0000;
    background: linear-gradient(180deg, #ecd3fe, #fbcafe);
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -.15px;
    line-height: 30px;
    margin: 0;
    text-align: left;
  }


  /* Animation keyframes */
  @keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(80px); /* Start from below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at the original position */
    }
  }

  /* Animation classes */
  .slide-in-up {
    animation: slideInUp 1.5s ease forwards;
  }

  .slide-in-up-delay {
    animation: slideInUp 1.5s ease forwards;
    animation-delay: 0.3s;
  }

  .slide-in-up-delay2 {
    animation: slideInUp 1.5s ease forwards;
    animation-delay: 0.6s;
  }

}


@media (min-width: 600px) {

  .home-section-4 {
    padding: 40px 15px;
  }

}


@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    text-align: center;
  }

  .images-container {
    order: 2;
  }

  .text-container {
    max-width: 100%;
    margin-top: 20px;
  }
}


@media (min-width: 1024px) {

  .home-section-4 {
    padding: 0px 40px 0 40px;
    background: linear-gradient(180deg, #101010 0%, #000 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  .homeHeadingH2 {
    text-align: left;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    margin:0;
  }

  .images-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0;
    width:100%;
  }

  .image {
    height: auto;
    border-radius: 10px;
  }

  .home-section-4 .image1 {
    width: 40%;
    margin: 0 0 0 10%;
  }
  .image2withText {
    display:flex;
    gap:40px;
    flex-direction: column;
    width:30%;
    margin: 0 0 0 10%;
  }
  .home-section-4 .image2 {
    width: 100%;
    height:auto; 
  }

  .text-container {
    width: 100%;
  }

  .text-container p {
    font-size: 21px;
    font-weight: 600;
    line-height: 25px; 
    letter-spacing: -0.15px;
    background: linear-gradient(180deg, #ECD3FE 0%, #FBCAFE 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}


@media (min-width: 1280px) {

  .home-section-4 {
    padding: 0px 40px 0 40px;
    background: linear-gradient(180deg, #101010 0%, #000 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  .homeHeadingH2 {
    text-align: left;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    margin:0;
  }

  .images-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0;
    width:100%;
  }

  .image {
    height: auto;
    border-radius: 10px;
  }

  .home-section-4 .image1 {
    width: 40%;
    margin: 0 0 0 10%;
  }
  .image2withText {
    display:flex;
    gap:40px;
    flex-direction: column;
    width:30%;
    margin: 0 0 0 10%;
  }
  .home-section-4 .image2 {
    width: 100%;
    height:auto; 
  }

  .text-container {
    width: 100%;
  }

  .text-container p {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px; 
    letter-spacing: -0.15px;
    background: linear-gradient(180deg, #ECD3FE 0%, #FBCAFE 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}


@media (min-width: 1600px) {


}