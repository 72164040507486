.AnalyticsTestingPage .whiteLogo {
    display: none;
}

.analyticsMainWrapper {
    padding: 15px;
    margin: 0;
    background: #fff;
    border-radius: 5px;
}

.analyticsMainWrapper h1 {
    font-size: 21px;
    margin: 0 0 20px 0;
    padding: 0;
}

.analyticsContentWrapper {
    padding: 15px;
    border: 1px solid #cdd3df;
    border-radius: 5px 5px 0 0;
    background: #f4f8ff;
}

.analyticsContentWrapper h2 {
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    padding: 0;
}

.analyticsContentWrapper .ant-form {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: end;
    column-gap: 20px;
    margin-top: 10px;
}

.analyticsContentWrapper .ant-form .ant-form-item {
    flex: 1;
    margin: 0;
}

.analyticsContentWrapper .ant-form .ant-form-item .ant-picker {
    width:100%;
}

.analyticsContentExistingTests {
    padding: 15px;
    border: 1px solid #cdd3df;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background: #fff;
}

.analyticsContentExistingTests h2 {
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    padding: 0;
}

.analyticsContentExistingTests .ant-table-wrapper {
    margin-top:10px;
}

.analyticsContentExistingTests .ant-table-wrapper .ant-table-thead .ant-table-cell {

}

.analyticsContentHeatmapSection {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #cdd3df;
    border-radius: 5px;
    background: #fff;
}

.analyticsContentHeatmapSection h2 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 10px 0;
    padding: 0;
}