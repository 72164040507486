/* Lading Page styles */


/* Media Queries */

@media (min-width: 300px) {
    .hidden /* .HeroMainParent */{
        display:none;
        height: auto;
    }
    .page-contents .defaultLogo {
      display:block;
    }
    .page-contents .whiteLogo {
      display:none;
    }
    .page-contents header {
      position:fixed;
      z-index: 999;
    }
    .page-contents header.scrolled {
        background: #fff;
    }

    .page-contents .header-links li a, .header-right-actions li a {
      color:#272d32;
    }
    .page-contents .header-right-actions button {
      color:#272d32;
    }
    .page-contents {
      background:#000;
    }
    .homeHeadingH2 {
        margin: 0 10% 30px 10%;
        width: 80%;
    }
    .homeHeadingH2 h2 {
        font-size:35px;
        line-height: 40px;
        margin-bottom: 5px;
        margin-top:10px;
    }
    .homeHeadingH2 p {
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        margin-bottom: 0px;
    }

    .HeadingKeyline {
        width: 10%;
        height: 2px;
    }

    .HeroMainParent {
        min-height: 388px;
        height: auto;
        margin: 0;
        width: 100%;
        overflow: hidden;
    }
    .HeroTopSections {
        margin: 0;
        width: 100%;
        height: auto;
    }
    /*  Hero section  */
    .landing-hero {
        height: 270px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 0;
        margin: 0;
        width: 100%;
        border-radius: 0;
        display: block;
        margin-bottom: 185px;
    }
    .landing-hero-shape {
        bottom: -187px;
        position: absolute;
    }
    .hero-content {
        padding: 40px 15px 20px 15px;
        width: 100%;
        background-size: 120%;
    }
    .hero-content h1 {
        font-size: 24px;
        line-height: 30px;
        font-weight:500;
        margin: 0 0 10px 0;
    }
    .hero-content p {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: #272d32;
        margin: 0 0 15px 0;
    }
    .hero-content .descriptor {
        font-size: 15px;
        line-height: 25px;
        margin: 5px 0 5px 0;
    }

    /*  Key features section. */
    .key-features {
        padding: 40px 15px 60px 15px;
    }
    .key-features h2 {
        font-size: 21px;
        line-height: 28px;
        font-weight:500;
        width: 100%;
        margin: 0;
    }
    .key-features .features-container {
        margin: 10px 0;
        row-gap: 60px;
        flex-direction: column;
    }
    .key-features .features-container .feature {
        width: 100%;
    }
    .key-features .features-container .feature .icon {
        height:150px;
    }
    .key-features .features-container .feature .icon svg {
        height: 150px;
        width: 288px;
    }
    .key-features .features-container .feature h3 {
        font-size: 18px;
        font-weight:500;
        margin: 10px 0;
        width: 100%;
    }
    .key-features .features-container .feature p {
        font-size: 15px;
        line-height: 23px;
        width: 100%;
        margin: 0;
        padding: 0;
        color: #272d32;
    }
    .buttons-row {
        flex-direction: column;
        row-gap: 15px;
    }

    /*  Content section  */
    .content-section {
        padding: 30px 0 0 0;
    }
    .content-row {
        padding: 0 15px 60px 15px;
    }
    .content-section .content-row h2 {
        font-size: 21px;
        line-height: 28px;
        font-weight:500;
        order:1;
        margin: 0 0 15px 0;
        width:100%;
    }
    .content-section .content-row p {
        font-size: 15px;
        line-height: 25px;
        margin: 0 0 15px 0;
        width: 100%;
        order:2;
        color: #272d32;
    }
    .content-section .content-row img {
        order:3;
    }

    /*  Pricing table  */
    .pricing-table {
        padding: 0;
    }
    .pricing-table .section-heading {
        font-size: 24px;
        line-height: 30px;
        font-weight:500;
        margin:0 0 5px 0;
        padding:0px 15px 0 15px;
    }
    .pricing-table .heading-description {
        font-size: 15px;
        line-height: 25px;
        margin: 0 0 15px 0;
        width: 100%;
        padding:0px 15px 0 15px;
    }
    .pricing-table .container {
        flex-direction: column;
    }
    .pricing-table .container .subscriptions {
        width: 100%;
        padding: 0 0 15px 0;
    }
    .pricing-table .container .subscriptions h2 {
        font-size: 18px;
        line-height: 26px;
        font-weight:500;
        padding:0px 15px 0 15px;
    }
    .pricing-table .container .options-row {
        overflow-y: scroll;
        padding:0 15px 15px 15px;
    }

    .pricing-table .container .options-row .option {
        min-width: 200px;
    }

    .pricing-table .container .addons {
        width: calc(100% - 30px);
        margin: 30px 15px;
    }
    .pricing-table .container .addons h2 {
        padding:0;
        font-weight:600;
    }
    .pricing-table .container .addons .addon-section {
        margin: 0 0 20px 0;
        padding: 15px;
        width: initial;
    }

    /*  Main Footer  */
    .main-footer {
        padding: 30px 15px 0 15px;
    }
    .footer-top h2 {
        font-size: 32px;
        line-height: 40px;
        font-weight:500;
        margin: 0 0 10px 0;
        width: 100%;
    }
    .footer-top p {
        margin: 0 0 20px 0;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
    }

    /*  Footer Links  */
    .footer-links {
        margin: 45px 0 0 0;
    }
    .footer-links .links-row {
        row-gap: 20px;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
    }
    .footer-links .links-row .link-column {
        row-gap: 5px;
        width:100%;
        align-items: center;
    }
    .links-row.bottom {
        margin: 20px 0 15px 0;
        padding: 5px 0;
        display: flex;
        flex-direction: column;
    }
    .footer-buttons {
        flex-direction: column;
        row-gap: 15px;
    }
}


@media (min-width: 385px) {
  .landing-hero {
    margin: 0 0 140px 0;
    height: 320px;
  }

  .landing-hero-shape {
    bottom: -140px;
  }
  
  .hero-content {
    background-size: 100%;
    padding: 40px 15px 30px 15px;
    width: 430px;
    height: auto;
  }
}


@media (min-width: 405px) {
    .landing-hero {
    margin: 0;
    height: 400px;
  }

  .landing-hero-shape {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
}


@media (min-width: 450px) {
  .landing-hero {
    margin: 0;
    height: 400px;
  }

  .landing-hero-shape {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .hero-content {
    background-size: 100%;
    padding: 40px 15px 30px 15px;
    width: 430px;
  }
}


@media (min-width: 600px) {
    .HeroMainParent {
        min-height: 388px;
      }
    .key-features .features-container {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .key-features .features-container .feature {
        width: calc(50% - 15px);
    }
}


@media (min-width: 768px) {
    .homeHeadingH2 {
        margin: 0 10% 0 10%;
        width: 80%;
    }
    .homeHeadingH2 h2 {
        font-size:35px;
        line-height: 40px;
    }
    .homeHeadingH2 p {
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 30px;
    }

    .pricing-table .container .addons {

    }
    .pricing-table .container .addons .addon-section {
        width: calc(33.3% - 30px);
    }
    .footer-links .links-row {
        flex-wrap: nowrap;
        column-gap: 20px;
    }
    .links-row.bottom {
        flex-direction: row;
        margin-bottom: 0;
    }
}


@media (min-width: 1024px) {
    .page-contents header.scrolled {
        background: #00000080;
    }
    .page-contents .defaultLogo {
      display:none;
    }
    .page-contents .whiteLogo {
      display:block;
    }
    .page-contents .header-links li a, .header-right-actions li a {
      color:#fff;
    }
    .page-contents .header-right-actions button {
      color:#fff;
    }

    .HeroMainParent {
        height: 611px;
        width: calc(100% - 80px);
        margin: 60px 40px 0 40px;
    }
    .HeroTopSections {
        margin: 0;
        width: 100%;
        height: auto;
    }

    .homeHeadingH2 {
        margin: 0 10% 0 10%;
        width: 80%;
    }
    .homeHeadingH2 h2 {
        font-size:40px;
        line-height: 45px;
    }
    .homeHeadingH2 p {
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 30px;
    }

    /* Landing Hero */
    .landing-hero {
      height: 635px;
      padding: 50px 0 0 0;
      width: calc(100% - 20px);
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      justify-content: flex-start;
      margin: 0 10px;
      border-radius:10px;
      background-image: url("../assets/landing-hero-bg-img.png");
    }

    .hero-content {
      background-image: url("../assets/landing-hero-shape.svg");
      padding: 170px 120px 72px;
      background-repeat: no-repeat;
      width: 580px;
      background-size: contain;
    }

    .hero-content .descriptor {
      font-size: 18px;
      color: #00152D;
      margin: 0 0 10px 0;
      display: flex;
    }
    .hero-content h1 {
      font-size: 43px;
      line-height: 60px;
      font-weight: 400;
      margin: 0 0 72px 0;
      width: 86%;
    }
    .hero-content p {
      color: #00152D;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 38px;
      letter-spacing: -0.15px;
      margin: 0 0 20px 0;
    }
    .hero-content .buttonPrimary {
      color: #FFF;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.15px;
      border-radius: 5px;
      border: 1px solid #006FF1;
      background: #006FF1;
      display: flex;
      padding: 15px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    /* Landing Content Sections */
    .content-section {
      padding: 85px 0 0 0;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .content-row {
      padding: 0px 120px 100px 120px;
      display: flex;
      flex-direction: column;
    }

    .content-section .content-row h2 {
      font-size: 38px;
      line-height: 56px;
      font-weight: 400;
      margin: 0 20% 10px 20%;
      text-align:center;
      color:#00152D;
      width:60%;
    }
    .content-section .content-row p {
      color: #00152d;
      font-size: 18px;
      font-weight: 400;
      line-height: 34px;
      margin: 0px 10% 20px 10%;
      text-align: center;
      width:80%;
    }
    .content-row:nth-child(1){

    }
    .content-row:nth-child(2){
      background: linear-gradient(180deg, #D3E2FF 0%, #FDC9FE 100%);
    }
    .content-row:nth-child(3){
      background: linear-gradient(180deg, #FDC9FE 0%, #FFE1A8 100%);
    }
    .content-row:nth-child(4){
      background: linear-gradient(180deg, #FFE1A8 0%, #97D8D2 100%);
    }
    .content-row:nth-child(5){
      background: linear-gradient(180deg, #97D8D2 0%, #88DACB 100%);
    }
    .content-row:nth-child(6){
      background: linear-gradient(180deg, #88DACB 0%, #88c4da 100%);
    }
    .content-row:nth-child(7){
      background: linear-gradient(180deg, #88c4da 0%, #9da8f2 100%);
    }
    .content-row:nth-child(8){
      background: linear-gradient(180deg, #9da8f2 0%, #c19df2 100%);
    }
    .content-row:nth-child(9){
      background: linear-gradient(180deg, #c19df2 0%, #f29dec 100%);
    }

    /* Pricing Table */
    .pricing-table {
      padding: 0 130px 100px 130px;
      display: flex;
      flex-direction: column;
      background: linear-gradient(180deg, #f29dec, #f29dbc);
    }
    .pricing-table .section-heading {
      color: #00152d;
      font-size: 38px;
      font-weight: 400;
      line-height: 56px;
      margin: 0;
    }
    .pricing-table .section-description {
      margin: 10px 0 40px 0;
    }
    .pricing-table .container {
      width: 100%;
      display: flex;
      flex-direction: row;
      column-gap: 30px;
    }
    .pricing-table .container .subscriptions {
      width: 80%;
    }
    .pricing-table .container .subscriptions h2 {
      color: #00152D;
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.25px;
      margin:0 0 20px 0;
    }
    .pricing-table .container .options-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      column-gap: 20px;
    }
    .pricing-table .container .options-row .option {
      padding: 15px 15px 5px 15px;
      width: 25%;
      border-radius: 10px;
    }
    .pricing-table .container .options-row .option:nth-child(1) {
      background-color: #ffffff30;
    }
    .pricing-table .container .options-row .option:nth-child(2) {
      background-color: #ffffff40;
    }
    .pricing-table .container .options-row .option:nth-child(3) {
      background-color: #ffffff50;
    }
    .pricing-table .container .options-row .option:nth-child(4) {
      background-color: #ffffff60;
    }
    .pricing-table .container .options-row .option h3 {
      color: #00152D;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.25px;
      margin:0 0 2px 0;
    }
    .pricing-table .container .options-row .option p {
      color: #00152D;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.25px;
      margin:0 0 20px 0;
    }
    .pricing-table .container .options-row .option p > span {
      color: #00152D;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.25px;
    }
    .pricing-table .container .options-row .option button {
      display: flex;
      height: 32px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 5px;
      background: #FFFFFF;
      margin-bottom: 20px;
      width: 100%;
      border: 1px solid white;
    }
    .pricing-table .container .options-row .option h4 {
      color: #00152D;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.25px;
      border-top:1px solid #00000025;
      padding:15px 0;
      margin:0;
    }
    .pricing-table .container .options-row .option div div {
      color: #00152D;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.25px;
      margin: 0 0 10px 0;
    }
    .pricing-table .container .addons {
      width: 20%;
      display: flex;
      flex-direction: column;
    }
    .pricing-table .container .addons h2 {
      color: #00152D;
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.25px;
      margin:0 0 20px 0;
    }
    .pricing-table .container .addons .addon-section {
      padding: 15px;
      width: 100%;
      border-radius: 10px;
      margin: 0 0 20px 0;
    }
    .pricing-table .container .addons .addon-section h3 {
      color: #00152D;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.25px;
      margin:0 0 5px 0;
    }
    .pricing-table .container .addons .addon-section:first-of-type {
      background-color: #e18ad0;
    }
    .pricing-table .container .addons .addon-section:nth-child(3) {
      background-color: #00152D10;
    }
    .pricing-table .container .addons .addon-section:nth-child(4) {
      background-color: #00152D10;
    }
    .pricing-table .container .addons .addon-section .addon-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid #00000025;
    }
    .pricing-table .container .addons .addon-section .addon-item:last-of-type {
      border-bottom:none;
      padding:10px 0 0 0;
    }

    /* Landing Key Features */
    .key-features {
      padding: 100px 130px 50px 130px;
      row-gap: 20px;
      display: flex;
      flex-direction: column;
    }
    .key-features h2 {
      color: #00152d;
      font-size: 38px;
      font-weight: 400;
      line-height: 56px;
      margin: 0 20% 10px;
      text-align: center;
      width: 60%;
    }

    .key-features .features-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      column-gap: 30px;
      justify-content: center;
      row-gap: 40px;
      margin: 40px 0px;
    }

    .key-features .features-container .feature {
      width: calc(33.3% - 30px);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .key-features .features-container .feature h3 {
      color: #00152d;
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -.25px;
      line-height: normal;
      margin: 20px 0 5px 0;
    }

    .key-features .features-container .feature p {
      font-size: 15px;
      line-height: 23px;
      margin: 10px 0 0 0;
      padding: 0 60px;
    }
    .buttons-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 20px;
    }
    .buttons-row button:first-of-type {
      display: flex;
      padding: 15px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      border: 1px solid #016ff1;
      background: #016ff1;
      font-weight: 600;
      color:#fff;
    }
    .buttons-row button:last-of-type {
      display: flex;
      padding: 15px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      border: 1px solid #016ff1;
      background: none;
      color: #016ff1;
      font-weight: 600;
    }


    /* Main Footer */
    .main-footer {
      background: #000000;
      padding: 60px 50px 10px;
    }
    .footer-top h2 {
      color: #fff;
      font-size: 38px;
      font-weight: 400;
      line-height: 50px;
      margin: 0 20% 15px 20%;
      text-align: center;
      width: 60%;
    }
    .footer-top p {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 34px;
      margin: 0 25% 35px 25%;
      text-align: center;
      width: 50%;
    }
    .footer-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 20px;
    }
    .footer-buttons button:first-of-type {
      display: flex;
      padding: 15px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      border: 1px solid #FFF;
      background: #FFF;
      font-weight: 600;
    }
    .footer-buttons button:last-of-type {
      display: flex;
      padding: 15px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      border: 1px solid #FFF;
      background: none;
      color: #fff;
      font-weight: 600;
    }
    .footer-links {
      margin: 100px 0 0 0;
    }
    .footer-links .links-row {
      column-gap: 10%;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
    .footer-links .links-row .link-column {
      row-gap: 12px;
      display: flex;
      flex-direction: column;
      color: #fff;
    }
    .footer-links .links-row .link-column a {
      color: #ffffff80;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.5px;
      text-decoration: none;
    }
    .links-row.bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #ffffff80;
      font-weight: 400;
      font-size: 14px;
      align-items: center;
      padding: 20px 0;
      border-top: 1px solid #ffffff50;
      margin: 50px 0 0 0;
    }
}

@media (min-width: 1280px) {

    .HeroMainParent {
      height: 770px;
    }

    .homeHeadingH2 {
        margin: 0 0 0 20%;
        width: 80%;
    }
    .homeHeadingH2 h2 {
        font-size:45px;
        line-height: 50px;
    }
    .homeHeadingH2 p {
        font-size: 21px;
        line-height: 28px;
        font-weight: 300;
        margin-bottom: 30px;
    }
}

@media (min-width: 1440px) {

}

@media (min-width: 1920px) {

}
