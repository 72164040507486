/* UserDashboard CSS */
.UserDahsboardWrapper {
	display: flex;
    flex-direction: column!important;
    box-sizing: border-box;
    background: none;
    height: 100vh;
}
.UserDahsboardWrapper .whiteLogo {
	display: none;
}
.UserDahsboardWrapper .header-right-actions li a {
	color: #00152d;
}


.UserDashboardContainer {
	display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 10px;
    margin: 0 10px 10px 10px;
}

.userDashboardLeftSidebar {
	margin: 0!important;
    min-width: 220px;
    padding: 0;
    width: 220px;
    flex-grow: 1;
    background: none;
}
.userDashboardLeftSidebar .ant-layout-sider-children {
	background-color: #fff;
    margin: 0;
    width:100%;
    padding: 0;
    flex-grow: 1;
    border-radius: 5px;
}
.userDashboardLeftSideBarLabel {
    width: 100%;
    align-items: center;
    background-color: #f1f1f1;
    border-bottom: 1px solid #cecece;
    border-radius: 5px 5px 0 0;
    display: flex;
    height: 35px;
}
.userDashboardLeftSideBarLabel p {
	color: #767676;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    padding: 0 10px;
    width: 100%;
}

.userDashboardLeftSidebar .ant-layout-sider-children ul {
	display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    row-gap: 2px;
}
.userDashboardLeftSidebar .ant-layout-sider-children ul li {
	border-bottom: 1px solid #e5e4e4;
    height: 31px;
    list-style: none;
    margin:0;
    padding:0 10px;
    width:100%;
    color: #00152d;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-overflow: ellipsis;
    padding-left:10px;
    border-radius: 0;
}
.userDashboardLeftSidebar .ant-layout-sider-children ul li.ant-menu-item-selected {
	background-color: #e2ecff!important;
}

.settings-content-area {
	background: none;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 0;
}
.settings-content-area .ant-layout-content {
	display: flex;
    flex-direction: row;
    gap: 10px;
}

.user-dashboard-content-wrap {
	width: 60%;
	height:100%;
	background-color: #fff;
	border-radius: 5px;
	padding:20px;
	margin:0;
}

.user-dashboard-content-wrap h1 {
	font-size:18px;
	font-weight: 600;
	margin:0 0 20px 0;
	padding:0;
	color: #00152d;
}
.user-dashboard-content-wrap h2 {
	font-size:16px;
	font-weight: 600;
	margin:0;
	padding:0;
	color: #00152d;
}

.user-public-url-wrapper h2 {
  margin-top: 30px;
}

.account-settings-wrapper, .jira-integration-wrapper {
 	display: flex;
  flex-direction: column;
  gap: 10px;
 }
 .jira-integration-wrapper h2 {
  margin-top: 30px;
 }
 .account-settings-wrapper h2 {
  margin-top: 20px;
 }

 .primary-button {
 	margin-top: 0px;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.15px;
    border-radius: 5px;
    border: 1px solid #006FF1;
    background: #006FF1;
    display: flex;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
 }

 .secondary-button {
 	margin-top: 0px;
    color: #006FF1;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.15px;
    border-radius: 5px;
    border: 1px solid #006FF1;
    background: #fff;
    display: flex;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
 }

.account-settings-input, .account-settings-select {
	display: flex;
  flex-direction: column;
  gap: 5px;
}
.account-settings-input {
  margin:0;
}
.account-settings-buttons {
	display: flex;
    flex-direction: row;
    gap: 10px;
}

.github-details-wrapper {
	gap: 15px;
  display: flex;
  flex-direction: column;
}
.github-details-wrapper h2 {
  margin:30px 0 0 0;
}
.github-details-wrapper p {
  margin:0;
}

.custom-domain-wrapper {
  margin:20px 0 0 0;
}
.custom-domain-wrapper h2 {
  margin: 0 0 15px 0;
}

.user-dashboard-storage-wrap {
	width: 40%;
	height:100%;
	background-color: #fff;
	border-radius: 5px;
	padding:20px;
	margin:0;
}
.user-dashboard-storage-wrap h2 {
	font-size: 18px;
    font-weight: 600;
	margin:0;
	padding:0;
	color: #00152d;
}

.data-usage-breakdown-wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.data-storage-bar-indicator {
    display: flex;
    flex-direction: column;
    margin:0;
}

.data-storage-bar-indicator-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.data-storage-bar-indicator-content-gb-usage {
	display: flex;
	align-items: baseline;
	gap:5px;
}

.data-storage-bar-indicator-content-gb-usage h4 {
	font-size: 18px;
    margin: 0;
    padding: 0;
}

.data-storage-bar-indicator-bar {
	display: flex;
	height: 32px;
	margin-top: 10px;
	background-color: #f1f1f1;
    border-radius: 5px;
    overflow:hidden;
}

.data-storage-bar-indicator-bar div {
	height: 100%;
}

.data-storage-bar-indicator-bar-documents {
  background-color: #4caf50; /* Green */
}

.data-storage-bar-indicator-bar-images {
  background-color: #2196f3; /* Blue */
}

.data-storage-bar-indicator-bar-videos {
  background-color: #f44336; /* Red */
}

.data-storage-bar-indicator-bar-icons {
  background-color: #ff9800; /* Orange */
}

.data-storage-bar-indicator-bar-music {
  background-color: #9c27b0; /* Purple */
}

.data-storage-breakdown-details {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	flex-direction: column;
	padding: 10px 20px;
	background: #f1f1f1;
	border-radius: 10px;
}
.data-storage-breakdown-details-seperator {
	height: 1px;
	width: 100%;
	background-color: #cecece;
}
.data-storage-breakdown-details-seperator:last-of-type {
	display: none;
}

.data-storage-breakdown-details > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-storage-breakdown-details-label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.data-storage-breakdown-details-label-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  /* Add your icon styles or images here */
}


.current-membership {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.current-membership h3, .current-membership p {
  margin:0;
}

.membership-wrapper h2 {
  margin:25px 0 15px 0;
}
.membership-options {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}
.membership-options .membership-option:nth-child(1)  {
  flex: 1;
  padding: 20px;
  background: #FFF;
  border:1px solid #D6E3FC;
  border-radius: 5px;
  color: #00152D;
}
.membership-options .membership-option:nth-child(2)  {
  flex: 1;
  padding: 20px;
  background: #D5E3FD;
  border-radius: 5px;
  color: #00152D;
}
.membership-options .membership-option:nth-child(3)  {
  flex: 1;
  padding: 20px;
  background: #006FF1;
  border-radius: 5px;
  color: #fff;
}
.membership-options .membership-option:nth-child(3) button {
  color:#fff;
}
.membership-options .membership-option h3 {
  margin:0;
}
.membership-options .membership-option p {
  margin:10px 0;
}
.membership-options .membership-option ul {
  margin:5px 0 20px 0;
  padding: 0 0 0 15px;
}
