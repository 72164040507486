*, *::before, *::after {
  box-sizing: border-box;
}

.mediaWrapper header {
    height: 45px;
}
.mediaWrapper header .logo img {
    height: 16px;
}
.mediaWrapper header ul li a {
    font-size: 14px;
}
.mediaWrapper header .header-right-actions button, .mediaWrapper header .header-right-actions li a {
    font-size: 14px;
    color:#00152d;
}

.mediaWrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.mediaWrapper .whiteLogo {
  display: none;
}

.MediaContents {
  display: flex;
  flex-direction: row;
  height: calc(100% - 45px);
  width: 100vw;
}

.MediaMain {
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 10px 10px;
  overflow: hidden;
  padding: 0px;
  width: 100%;
}
.MediaMain h3 {
  align-items: center;
  background-color: #f1f1f1;
  border-bottom: 1px solid #cecece;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: row;
  height: 35px;
  justify-content: flex-start;
  color: #767676;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  padding: 0 10px;
  width: 100%;
}

.mediaContainer {
  flex-grow: 1;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.mediaItem {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  margin: 5px;
}

.MediaMainTopBar {
  display: flex;
  flex-direction: row;
}

.MediaMain .loading-spinner {
  position: absolute;
  left:calc(50% + 110px);
  top:50%;
  display:flex;
}