/* UI Builder.css */
body {
	background: linear-gradient(180deg, #D3E2FF 0%, #D3E2FF 100%);
}
button, a, link, select, input {
    cursor:pointer;
}
input:focus, select:focus {
    outline: none!important;
}

.builder-wrap header {
    height: 45px;
}
.builder-wrap .whiteLogo {
    display:none;
}
.builder-wrap header .logo img {
    height: 16px;
}
.builder-wrap header ul li a {
    font-size: 14px;
}
.builder-wrap header .header-right-actions button, .builder-wrap header .header-right-actions li a {
    font-size: 14px;
    color:#00152d;
}

.ui-builder-container {
	display: flex;
    flex-direction: row;
    height:calc(100% - 45px);
    width:100vw;
}


/* Left Sidebar styling */
.vEditLeftSidebar {
	border-radius:10px;
	background-color:#ffffff;
	margin:0 0 10px 10px!important;
	padding:0;
	width:220px;
    overflow:hidden;
}
.vEditLeftSidebar .LeftSideBarTopControls {
	background-color: #F1F1F1;
    height: 35px;
    border-bottom: 1px solid #CECECE;
    display: flex;
    align-items: center;
    border-radius:5px 5px 0 0;
    padding-left:10px;
}
.DragHandler {
	margin: 11px;
    display: flex;
    align-items: center;
}
.vEditLeftSidebar .LeftSideBarTopControls button {
	border: none;
    padding: 0 15px 0 0;
    height: 100%;
    font-weight:700;
    font-size:12px;
    color:#767676;
}
.vEditLeftSidebar .LeftSideBarTopControls button:hover {
    color:#3e3e3e;
}
.vEditLeftSidebar .LeftSideBarTopControls button.active {
	color:#00152D;
}
/* Ui builder top controls */
.uiBuilderTopControls {
	background: #F1F1F1;
    border-bottom: 1px solid #cdcdcd;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
}
.uiBuilderTopControls .ant-select {
    height:28px;
    border-right: 1px solid #cecece;
}
.uiBuilderTopControls .ant-select-selector {
    background: none !important;
    border: none!important;
    border-radius: 0;
}
.uiBuilderTopControls .ant-select:hover .ant-select-selector {
    background: #ffffff70!important;
}
.uiBuilderTopControls .ant-select-selection-item {
    font-size: 12px;
    color: #000000;
}
.uiBuilderTopControls .ant-select-arrow {
    color: #000000;
}

.uiBuilderTopControls .responsiveButtonsGroup {
    display: flex;
}
.uiBuilderTopControls .ant-select .ant-select-selection-search-input {
    height:28px;
    border-right: 1px solid #cecece;
}

.sidebar-controls {
	column-gap: 0;
    display: flex;
}
.sidebar-controls button {
	border: none;
    border-right: 1px solid #cecece;
    height: 28px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 12px;
}
.sidebar-controls button:hover {
    background: #ffffff70;
}

/* Component Sidebar */
.left-sidebar {
	width:220px;
	padding:0px;
	float:left;
	display:block;
    height: calc(100% - 32px);
    overflow: scroll;
}
.left-sidebar h4 {
    color: #00152d;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    padding: 15px 10px 8px 10px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #dddddd;
    line-height: 12px;
}
.left-sidebar h4:first-of-type {
    padding: 8px 10px 8px 10px;
}
.left-sidebar ul {
    margin: 0;
    padding: 0;
    display: flex;
    row-gap: 2px;
    flex-direction: column;
}
.left-sidebar ul li {
    display: flex;
    list-style: none;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    height: 31px;
    border-bottom: 1px solid #e5e4e4;
}
.left-sidebar ul li .component-left-content {
    align-items: center;
    column-gap: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 0 0 10px;
}
.left-sidebar ul li .component-left-content .componentThumbnail {
    width: 40px;
    height: 40px;
    border: 1px solid #cecece;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-sidebar ul li .component-left-content img {
    background: none;
    height: auto;
    width: auto;
    max-width: 40px;
}
.left-sidebar ul li .component-left-content p {
    color: #00152D;
    text-overflow: ellipsis;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0px;
}
.left-sidebar ul li button {
    background: none;
    border: none;
    margin: 0;
    height: 24px;
    width: 24px;
    border-radius: 2px;
    margin: 0 5px 0 0;
    color:#00152D;
}
.left-sidebar ul li button:hover {
    background:#f1f1f1;
    color:#006FF1;
}
.left-sidebar ul li.dragging {
    background: #FFE2FE;
}
.drag-image-container {
    width: 40px;
    height: 40px;
    border: 2px solid #FFE2FE;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background:#fff;
}
.mini-loading-label {
    font-size:8px;
}

.thumbnail-while-dragging {
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  border: 4px solid #000;
  margin:0 0 0 -10px;
  border-radius: 2px;
  overflow:hidden;
  box-sizing: border-box;
}


/* Pages Sidebar */
.pages-sidebar {
	display: flex;
    height: 100%;
    padding: 0;
    width: 220px;
    flex-direction: column;
}
.pages-sidebar h4 {
	font-size:12px;
	font-weight:700;
	color:#00152D;
	margin:0;
	padding:10px;
}
.pages-sidebar ul { 
	list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    border-bottom: 1px solid #e2ecff;
}
.pages-sidebar ul li { 
	align-items: center;
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: flex-start;
    padding: 2px 5px 2px 10px;
    border-top: 1px solid #e2ecff;
}
.pages-sidebar ul li:hover { 
    background-color: #E2ECFF50;
}
.pages-sidebar ul li.active { 
	background-color: #E2ECFF;
    font-weight:700;
}
.pages-sidebar ul li.active button {
	display: inline-block;
}
.pages-sidebar ul li.active button:hover {
    background: #cbd6ea;
    border-radius: 2px;
}
.pages-sidebar ul li span {
	width: 100%;
	font-size:12px;
	color:#00152D;
}
.pages-sidebar ul li span .anticon {
	width:14px;
	color:#00152D;
}
.pages-sidebar ul li:hover button {
	display: inline-block;
}
.pages-sidebar ul li button {
	border: none;
    background: none;
    width: 24px;
    height: 24px;
    margin: 0px;
    display:none;
}
.pages-sidebar ul li button:hover {
    background: #e8f0ff;
    border-radius: 2px;
}
.pages-sidebar .pages-sidebar-controls {
    padding: 0;
    display: flex;
    height: 28px;
    border-bottom: 1px solid #cecece;
    flex-direction: row;
}
.pages-sidebar .pages-sidebar-controls input{
    width: 100%;
    font-size: 12px;
    letter-spacing: -0.115px;
    line-height: 12px;
    text-overflow: ellipsis;
    border-radius: 0;
    border: none;
    border-right: 1px solid #e5e3e3;
    padding: 0 0px 0 10px;
    background: #f1f1f175;
}
.pages-sidebar .pages-sidebar-controls button {
    height: 28px;
    border-radius: 0;
    border: none;
    background: #f1f1f1;
    font-size: 12px;
    font-weight: 400;
    padding: 0 10px;
}
/* Templates Sidebar */
.templates-sidebar .templates-header h3 {
    background-color: #f1f1f1;
    border-bottom: 1px solid #ddd;
    color: #00152d;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    margin: 0;
    padding: 8px 10px;
}
.templates-sidebar .templates-header button {
    background-color: #f1f1f1;
    border-bottom: 1px solid #ddd;
    color: #00152d;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    margin: 0;
    padding: 8px 10px;
    width:100%;
    display:flex;
    justify-content: flex-start;
    border-radius:0;
}
.templates-sidebar .ant-collapse {
    border-radius:0;
}
.templates-sidebar .ant-collapse .ant-collapse-item .ant-collapse-header {
    padding:4px 10px;
    margin:0;
    display:flex;
    column-gap:0;
}
.templates-sidebar .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon {
    padding-inline-end:5px;
}
.templates-sidebar .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-header-text {
    font-weight:600;
}

/* Right Sidebar Styling */
.ui-builder-container .vEditRightSidebar {
    margin: 0 10px 10px 0 !important;
    display: flex;
    flex-direction: column;
    width:233px;
}
.vEditRightSidebar .RightSideBarTopControls {
    background-color: #F1F1F1;
    height: 35px;
    border-bottom: 1px solid #CECECE;
    display: flex;
    align-items: center;
    border-radius:5px 5px 0 0;
}
.vEditRightSidebar .RightSideBarTopControls button {
    border: none;
    padding: 0 15px 0 0;
    height: 100%;
    font-weight:700;
    font-size:12px;
    color:#00152D;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.vEditRightSidebar .RightSideBarTopControls button.temp-disabled {
    color:#767676;
}
.vEditRightSidebar .RightSideBarTopControls button:hover {
    color:#3e3e3e;
}
.vEditRightSidebar .RightSideBarTopControls button.active {
    color:#00152D;
}


/* Properties sidebar */
.properties-sidebar {
	background-color: #fff;
    border-radius: 0 0 5px 5px;
    display: flex;
    margin: 0;
    max-width: 233px;
    min-width: 233px;
    padding: 0;
    height: 100%;
    flex-direction: column;
    overflow: scroll;
}
.properties-sidebar .ant-collapse {
	height: 100%;
    border-radius: 0;
    border: none;
    background: none;
}
.properties-sidebar .ant-collapse-header {
	border: none;
    flex-direction: row-reverse;
    padding: 0 !important;
    height: 28px;
    display: flex;
    align-items: center;
}
.properties-sidebar .ant-collapse-item {
    border-bottom: 1px solid #e2ecff;
}
.properties-sidebar .ant-collapse-item .ant-collapse-content {
    border: none;
    display: flex;
}
/* collapse heading item */
.properties-sidebar .ant-collapse-header .ant-collapse-header-text {
	font-size: 12px;
    line-height: 14px;
    padding: 7px 10px;
}
.properties-sidebar .ant-collapse-header  .ant-collapse-expand-icon {
 	padding-inline-end:8px!important;
 	min-height:28px!important;
}
.properties-sidebar .ant-collapse-header  .ant-collapse-expand-icon span {
    display: flex;
    align-items: center;
}
.properties-sidebar .ant-collapse-content .ant-collapse-content-box {
	padding:0 10px;
	padding-block:0!important;
    width:100%;
}
.properties-sidebar .ant-collapse-content .ant-collapse-content-box label {
	font-size:12px;
	line-height:14px;
	font-weight:500;
	padding:0 4px;
}
.properties-sidebar .ant-collapse-content .ant-collapse-content-box .ant-row {
	border: none;
    display: flex;
    margin: 0 0 8px 0!important;
    padding: 0;
    flex-direction: row;
    width: calc(100% - 10px);
    column-gap: 10px;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.properties-sidebar .ant-collapse-content .ant-collapse-content-box .ant-row.border-color-row {
    width: 100%;
}
.properties-sidebar .ant-collapse-content .ant-collapse-content-box .ant-row .ant-col {
	align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: none;
    height: 28px;
    width: 100%;
    min-width: 28px;
}
.properties-sidebar .colorPanel .ant-col {
	display: flex;
    flex-direction: column!important;
    align-items: flex-start!important;
}
.properties-sidebar .ant-collapse-content .ant-collapse-content-box .ant-row .ant-col label {
	font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    height: 28px;
    display: flex;
    align-items: center;
}
.flex-button {
    border:none;
}
.flex-button.active {
  background-color: #1890ff;
  color: white;
}
.properties-sidebar .prop-sidebar-item {
    display: flex;
    align-items: center;
    border: 1px solid #d9e0ee;
    border-radius: 3px;
    width:100%;
}
.properties-sidebar .prop-sidebar-item-2 { 
    align-items: center;
    border: 1px solid #d9e0ee!important;
    border-radius: 3px!important;
    display: flex;
}
.properties-sidebar .colorPanel .ant-collapse-content .ant-collapse-content-box .ant-row {
    width: calc(100% - 2px);
    display: flex;
    flex-direction: column;
}
.properties-sidebar .prop-sidebar-item-color {
    width:100%;
    margin:0;
}
.properties-sidebar .prop-sidebar-item button {
    cursor: pointer;
    width: 18px;
    padding: 0 2px;
    margin: 0 1px 0 0;
    border: none;
    border-radius: 0;
    height: 28px;
    background: #e8f0ff;
}
.properties-sidebar .prop-sidebar-item span {
    align-items: center;
    border-radius: 0 1px 1px 0;
    display: flex;
    font-size: 10px !important;
    height: 26px;
    padding: 0 2px;
    width: 12px;
}
.properties-sidebar .prop-sidebar-item span .anticon.anticon-down {
    font-size: 12px!important;
}
.properties-sidebar .ant-collapse-content .ant-collapse-content-box .ant-row .ant-col input {
	border: none;
    border-radius: 0;
    font-size: 12px;
    height: 28px;
    line-height: 12px;
    padding: 5px;
    width: 100%;
}
.properties-sidebar .ant-collapse-content .ant-collapse-content-box .ant-row .ant-col input:focus {
    outline: none!important;
}
.properties-sidebar .ant-collapse-content .ant-collapse-content-box .ant-row .ant-col buton {
    margin: 0;
    border-radius: 2px;
    border: none;
    width: 100%;
    height: 28px;
}
.properties-sidebar .ant-collapse-content .ant-collapse-content-box .ant-row .ant-col buton:hover {
    background: #e8f0ff;
}
.properties-sidebar .colorPanel .ant-collapse-content .ant-col input.color-picker {
	margin: 0;
    min-width: 24px;
    width: 24px;
    block-size: 24px;
    border-color: #d9d9d9;
    height: 24px!important;
    padding: 0 1.5px!important;
    background-color: #ffffff;
}
.properties-sidebar .border-color-picker {
    margin: 0;
    min-width: 24px;
    width: 24px!important;
    block-size: 24px;
    border-color: #d9d9d9;
    height: 24px!important;
    padding: 0 1.5px!important;
    background-color: #ffffff;
}
.prop-sidebar-color-item {
    display: flex;
    align-items: center;
    border: 1px solid #d9e0ee;
    border-radius: 3px;
}
.properties-sidebar .colorPanel .ant-col .anticon svg {
	position:absolute;
	right:3px;
}
.properties-sidebar-bottom-btn {
	margin:10px;
}

.properties-sidebar .propTypePanel {
    flex-direction: column;
}
.properties-sidebar .propTypePanel .ant-collapse-content .ant-collapse-content-box .ant-row {
    width:100%;
    display:block;
}
.propTypeographyItem {
    align-items: center;
    display: flex;
    width: 100%;
}
.properties-sidebar .propTypePanel .ant-collapse-content .ant-collapse-content-box .ant-row.propTypeRow2 {
    flex-direction: row;
    display:flex;
    width: calc(100% - 10px);
    row-gap:10px;
}

.properties-sidebar-bottom-btn button{
    background: none;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    height: 32px;
    padding: 0px 15px;
}
.properties-sidebar h5 {
	padding:0 15px;
}

/* Page Container */
.pages-container {
    margin: 0 10px 10px 10px;
    padding: 0;
    width: calc(100% - 493px);
}
.pages-container .tabbed-interface {
	width:100%;
	height:100%;
	display: flex;
    flex-direction: column;
}
.pages-container .tab-content {
	height: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    overflow: scroll;
}
.tabbed-interface ul.tabs{
	column-gap: 0;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    height: auto;
    border-bottom: 1px solid #cecece;
    flex-direction: row;
    width: 100%;
    background-color: #ECECEC;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}
.tabbed-interface ul.tabs li {
    padding: 0px 15px 0 15px;
    font-size: 12px;
    border-right: 1px solid #cecece;
    display: flex;
    height: 35px;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    font-weight: 700;
    color: #767676;
}
.tabbed-interface ul.tabs li span.anticon {
	display:none;
}
.tabbed-interface ul.tabs li.active span.anticon { 
	display:block;
}
.tabbed-interface ul.tabs li:hover{
	background-color:#ffffff70;
    color: #4a5159;
}
.tabbed-interface ul.tabs li.active {
    color: #00152D;
}
.pages-container .tab-content .tab {
	display:none;
	width:100%;
	height:auto;
	padding:0;
}
.pages-container .tab-content .tab.active {
	display:flex;
}
.builder-wrap .pages-container .page-container {
    width:auto!important;
}
.pages-container .page-container {
	width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.builder-wrap .pages-container .tab-content .page-container .container {
    border: 1px dashed #006FF1;
}
.pages-container .tab-content .page-container .container {
	display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 10px;
}
.bar-container {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.bar-container .column-options {
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 10px;
}
.bar-container .column-options button {
	background:none;
    width: 28px;
    height: 28px;
    border-radius: 2px;
    border: 1px solid #cecece;
}
.bar-container .column-options div {
	display: flex;
    flex-direction: row;
    align-items: center;
    height: 28px;
    column-gap: 2px;
}
.bar-container .column-options div select {
	height: 100%;
    width: 68px;
    border: 1px solid #989898;
    border-radius:2px;
}
.pages-container .tab-content .page-container .add-outter-row {
	display:flex;
}
.pages-container .tab-content .page-container button.add-row {
	background: none;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    height: 32px;
    padding: 0 15px;
}
.page-container .row {
    min-height: 32px;
    display: flex;
}
.builder-wrap .page-container .row .column {
    border: 1px dashed #d17ee4;
}
.page-container .row .column {
    border-radius: initial;
    min-height: 16px;
    padding: 0;
}
.selection-panel {
    background-color: #00152D;
    bottom: 0;
    padding: 20px;
    position: absolute;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    z-index:999;
}
.selection-panel header {
	color: #00152d;
    flex-direction: row;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 80px;
}
.selection-panel header h4 {
	color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 5px 0;
}
.selection-panel header button {
	background: none;
    color: #fff;
    border: none;
    height: 28px;
}
.selection-panel .tabs {
	width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}
.selection-panel .tabs button {
	border: none;
    background: #ffffff40;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 30px;
    padding: 0 15px;
    border-radius: 2px;
}
.selection-panel .visual-editor {
	display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.selection-panel .visual-editor div {
	display: flex;
    flex-direction: row;
    column-gap: 5px;
    color: #fff;
    font-size: 14px;
    align-items: center;
}
.selection-panel .visual-editor div input {
	padding: 5px 8px;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    font-size: 14px;
}

.selection-panel .visual-editor .selectionPanel-AddNestedContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
}
.selection-panel .visual-editor .SelectionPanel-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 20px 0 0 0;
    align-items: flex-start;
}
.selection-panel .visual-editor .SelectionPanel-content h2 {
    font-size:15px;
    font-weight:500;
    line-height:20px;
    color:#fff;
    margin:0;
}
.selection-panel .visual-editor .SelectionPanel-content p {
    font-size:14px;
    font-weight:400;
    line-height:20px;
    color:#eee;
    margin:0;
}
.selection-panel .visual-editor .SelectionPanel-controls {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin: 0;
}
.selection-panel .visual-editor .selectionPanel-AddnestedButton {
    height: 32px;
    width: 32px;
    display: flex;
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff; 
    color: #000000; 
    border: none;
    cursor: pointer;
}
.selection-panel .visual-editor .selectionPanel-AddNestedSelect {
    height: 32px;
    padding: 0;
    display: flex;
    border-radius: 5px;
    width:100%;
}

.SelectionPanel-controls {
    min-width: 300px;
}

/* DivContainer ComponentAddPanel styling */
.div-container {
    position: relative;
    /* Other styles */
}

.component-add-panel {
    position: absolute;
    top: -52px; /* 10px above the DivContainer */
    right: 0;
    z-index: 100; /* Ensure it's above other elements */
    background: white;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

/* Container control bar component*/
.ContainerControlBar {
    width: calc(100% - 20px);
    background-color: #e0e0e0;
    padding: 5px 10px;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: -25px;
    z-index: 100;
    display: none; /* Initially hidden */
    opacity: 0; /* Set opacity for a smooth fade-in transition */
    transition: opacity 0.3s ease; /* Smooth opacity transition */
}
.ContainerComponentWrapper:hover .ContainerControlBar {
    display: flex;
    opacity: 1;
}

/* Optional: This hides the control bar visually, but doesn't use display to avoid sudden disappearance */
.ContainerControlBar.hidden {
    opacity: 0;
    transition: opacity 0.3s ease;
}


.container {
    position: relative;
}

/* When hovering over a container (row), add a glow or outline */
.container.hovered {
  outline: 2px dashed #007aff;
  /* or you can add a box-shadow: */
  box-shadow: 0 0 10px rgba(0, 122, 255, 0.7);
}

/* The row select button (positioned within the container) */
.row-select-button {
    position: absolute;
    top: 0; /* adjust if needed */
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    font-size: 12px;
    background: #007aff;
    color: #fff;
    border: none;
    cursor: pointer;
    z-index: 10;
    opacity: 0.9;
}

/* Column select button */
.column {
  position: relative; /* so its absolute children are positioned relative to it */
}

.column.hovered {
    outline: 2px dashed #d17ee4;
    /* or you can add a box-shadow: */
    box-shadow: 0 0 10px rgba(0, 122, 255, 0.7);
}

.column-select-button {
    position: absolute;
    bottom: 0; /* adjust if needed */
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    font-size: 12px;
    background: #d17ee4;
    color: #000;
    border: none;
    cursor: pointer;
    z-index: 10;
    opacity: 0.9;
}