/* Add your CSS styles for the main application here */
/* For example, you can style the body or other elements as needed */
body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

.color-purple {
  color: #8200ff;
}
.color-white {
  color: #ffffff;
}
.color-green {
  color: #009F8C;
}
.color-black {
  color: #020005;
}
.bg-color-purple {
  background-color: #8200ff;
}
.bg-color-white {
  background-color: #ffffff;
}
.bg-color-green {
  background-color: #009F8C;
}
.bg-color-black-100 {
  background-color: #000000;
}
.bg-color-black-95 {
  background-color: #00000095;
}
.bg-color-black-90 {
  background-color: #00000090;
}
.bg-color-black-85 {
  background-color: #00000085;
}
.bg-color-black-80 {
  background-color: #00000080;
}
.bg-color-black-75 {
  background-color: #00000075;
}
.bg-color-black-70 {
  background-color: #00000070;
}
.bg-color-black-65 {
  background-color: #00000065;
}
.bg-color-black-60 {
  background-color: #00000060;
}
.bg-color-black-55 {
  background-color: #00000055;
}
.bg-color-black-50 {
  background-color: #00000050;
}
.bg-color-black-45 {
  background-color: #00000045;
}
.bg-color-black-40 {
  background-color: #00000040;
}
.bg-color-black-35 {
  background-color: #00000035;
}
.bg-color-black-30 {
  background-color: #00000030;
}
.bg-color-black-25 {
  background-color: #00000025;
}
.bg-color-black-20 {
  background-color: #00000020;
}
.bg-color-black-15 {
  background-color: #00000015;
}
.bg-color-black-10 {
  background-color: #00000010;
}
.bg-color-black-05 {
  background-color: #00000005;
}
.bg-color-gradient-home-1 {
  background: linear-gradient(180deg, #D3E2FF 0%, #FDC9FE 100%);
}
.bg-color-gradient-home-2 {
  background: linear-gradient(180deg, #FDC9FE 0%, #FFE1A8 100%);
}
.bg-color-gradient-home-3 {
  background: linear-gradient(180deg, #FFE1A8 0%, #97D8D2 100%);
}
.bg-color-gradient-home-4 {
  background: linear-gradient(180deg, #97D8D2 0%, #88DACB 100%);
}

.builder-wrap {
  height:100vh;
}

#root {
  width: 100vw;
  height: auto;
  overflow: hidden;
}

.page-contents {
  display: flex;
  width: 100%;
  height:100%;
  justify-content: flex-start;
  flex-direction: column;
  background: linear-gradient(180deg, #D3E2FF 0%, #D3E2FF 100%);
}

/* Header */

header {
  flex-direction: row;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00152D;
}

.logo {
  margin-left:20px;
}

.logo .logoLink {
  display:flex;
}

.logo img {
  height:20px;
}
.codeEditor .whiteLogo {
    display: none !important;
}

.header-links {
  display: flex;
  flex-direction: row;
  width: auto;
  column-gap: 0px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.header-links li a, .header-right-actions li a {
  color: #00152d;
  padding: 0 10px;
  text-decoration: none;
  font-size: 16px;
}

.header-right-actions {
  display: flex;
  flex-direction: row;
  width: auto;
  column-gap: 0px;
  list-style: none;
  padding: 0;
  margin: 0 20px 0 0;
}
.header-right-actions li {
  color: #00152d;
  padding: 0 10px;
  text-decoration: none;
  font-size: 14px;
}
.header-right-actions button, .header-right-actions li {
  background: none;
  border-radius: 0;
  border: none;
  padding: 0;
  color: #00152D;
  font-size:16px;
}

/* Style the "Start" button */
.start-button {
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.start-button:hover {
  background-color: #0056b3; /* Darker blue color on hover */
}

/* Landing Hero */

.landing-hero {
  height: 100%;
  padding: 50px 0 0 0;
  width: calc(100% - 20px);
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 10px;
  border-radius:10px;
  background-image: url("./assets/landing-hero-bg-img.png");
}

.hero-content {
  background-image: url("./assets/landing-hero-shape.svg");
  padding: 170px 120px 72px;
  background-repeat: no-repeat;
  width: 580px;
  background-size: contain;
}

.hero-content .descriptor {
  font-size: 18px;
  color: #00152D;
  margin: 0 0 10px 0;
  display: flex;
}
.hero-content h1 {
  font-size: 43px;
  line-height: 60px;
  font-weight: 400;
  margin: 0 0 72px 0;
  width: 86%;
}
.hero-content p {
  color: #00152D;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.15px;
  margin: 0 0 20px 0;
}
.hero-content .buttonPrimary {
  color: #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.15px;
  border-radius: 5px;
  border: 1px solid #006FF1;
  background: #006FF1;
  display: flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* Landing Content Sections */
.content-section {
  padding: 85px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content-row {
  padding: 0px 120px 100px 120px;
  display: flex;
  flex-direction: column;
}

.content-section .content-row h2 {
  font-size: 38px;
  line-height: 56px;
  font-weight: 400;
  margin: 0 20% 10px 20%;
  text-align:center;
  color:#00152D;
  width:60%;
}
.content-section .content-row p {
  color: #00152d;
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  margin: 0px 10% 20px 10%;
  text-align: center;
  width:80%;
}
.content-row:nth-child(1){

}
.content-row:nth-child(2){
  background: linear-gradient(180deg, #D3E2FF 0%, #FDC9FE 100%);
}
.content-row:nth-child(3){
  background: linear-gradient(180deg, #FDC9FE 0%, #FFE1A8 100%);
}
.content-row:nth-child(4){
  background: linear-gradient(180deg, #FFE1A8 0%, #97D8D2 100%);
}
.content-row:nth-child(5){
  background: linear-gradient(180deg, #97D8D2 0%, #88DACB 100%);
}
.content-row:nth-child(6){
  background: linear-gradient(180deg, #88DACB 0%, #88c4da 100%);
}
.content-row:nth-child(7){
  background: linear-gradient(180deg, #88c4da 0%, #9da8f2 100%);
}
.content-row:nth-child(8){
  background: linear-gradient(180deg, #9da8f2 0%, #c19df2 100%);
}
.content-row:nth-child(9){
  background: linear-gradient(180deg, #c19df2 0%, #f29dec 100%);
}

/* Pricing Table */
.pricing-table {
  padding: 0 130px 100px 130px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #f29dec, #f29dbc);
}
.pricing-table .section-heading {
  color: #00152d;
  font-size: 38px;
  font-weight: 400;
  line-height: 56px;
  margin: 0;
}
.pricing-table .section-description {
  margin: 10px 0 40px 0;
}
.pricing-table .container {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}
.pricing-table .container .subscriptions {
  width: 80%;
}
.pricing-table .container .subscriptions h2 {
  color: #00152D;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.25px;
  margin:0 0 20px 0;
}
.pricing-table .container .options-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 20px;
}
.pricing-table .container .options-row .option {
  padding: 15px 15px 5px 15px;
  width: 25%;
  border-radius: 10px;
}
.pricing-table .container .options-row .option:nth-child(1) {
  background-color: #ffffff30;
}
.pricing-table .container .options-row .option:nth-child(2) {
  background-color: #ffffff40;
}
.pricing-table .container .options-row .option:nth-child(3) {
  background-color: #ffffff50;
}
.pricing-table .container .options-row .option:nth-child(4) {
  background-color: #ffffff60;
}
.pricing-table .container .options-row .option h3 {
  color: #00152D;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.25px;
  margin:0 0 2px 0;
}
.pricing-table .container .options-row .option p {
  color: #00152D;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.25px;
  margin:0 0 20px 0;
}
.pricing-table .container .options-row .option p > span {
  color: #00152D;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.25px;
}
.pricing-table .container .options-row .option button {
  display: flex;
  height: 32px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  background: #FFFFFF;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid white;
}
.pricing-table .container .options-row .option h4 {
  color: #00152D;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.25px;
  border-top:1px solid #00000025;
  padding:15px 0;
  margin:0;
}
.pricing-table .container .options-row .option div div {
  color: #00152D;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.25px;
  margin: 0 0 10px 0;
}
.pricing-table .container .addons {
  width: 20%;
  display: flex;
  flex-direction: column;
}
.pricing-table .container .addons h2 {
  color: #00152D;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.25px;
  margin:0 0 20px 0;
}
.pricing-table .container .addons .addon-section {
  padding: 15px;
  width: 100%;
  border-radius: 10px;
  margin: 0 0 20px 0;
}
.pricing-table .container .addons .addon-section h3 {
  color: #00152D;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.25px;
  margin:0 0 5px 0;
}
.pricing-table .container .addons .addon-section:first-of-type {
  background-color: #e18ad0;
}
.pricing-table .container .addons .addon-section:nth-child(3) {
  background-color: #00152D10;
}
.pricing-table .container .addons .addon-section:nth-child(4) {
  background-color: #00152D10;
}
.pricing-table .container .addons .addon-section .addon-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #00000025;
}
.pricing-table .container .addons .addon-section .addon-item:last-of-type {
  border-bottom:none;
  padding:10px 0 0 0;
}

/* Landing Key Features */
.key-features {
  padding: 100px 130px 50px 130px;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
}
.key-features h2 {
  color: #00152d;
  font-size: 38px;
  font-weight: 400;
  line-height: 56px;
  margin: 0 20% 10px;
  text-align: center;
  width: 60%;
}

.key-features .features-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 30px;
  justify-content: center;
  row-gap: 40px;
  margin: 40px 0px;
}

.key-features .features-container .feature {
  width: calc(33.3% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.key-features .features-container .feature .icon svg {
  width: 50px;
  height: 50px;
}

.key-features .features-container .feature h3 {
  color: #00152d;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -.25px;
  line-height: normal;
  margin: 20px 0 5px 0;
}

.key-features .features-container .feature p {
  font-size: 15px;
  line-height: 23px;
  margin: 10px 0 0 0;
  padding: 0 60px;
}
.buttons-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 20px;
}
.buttons-row button:first-of-type {
  display: flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #016ff1;
  background: #016ff1;
  font-weight: 600;
  color:#fff;
}
.buttons-row button:last-of-type {
  display: flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #016ff1;
  background: none;
  color: #016ff1;
  font-weight: 600;
}


/* Main Footer */
.main-footer {
  background: #000;
  padding: 60px 50px 10px;
}
.footer-top h2 {
  color: #fff;
  font-size: 38px;
  font-weight: 400;
  line-height: 50px;
  margin: 0 20% 15px 20%;
  text-align: center;
  width: 60%;
}
.footer-top p {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  margin: 0 25% 35px 25%;
  text-align: center;
  width: 50%;
}
.footer-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 20px;
}
.footer-buttons button:first-of-type {
  display: flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #FFF;
  background: #FFF;
  font-weight: 600;
}
.footer-buttons button:last-of-type {
  display: flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #FFF;
  background: none;
  color: #fff;
  font-weight: 600;
}
.footer-links {
  margin: 100px 0 0 0;
}
.footer-links .links-row {
  column-gap: 10%;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.footer-links .links-row .link-column {
  row-gap: 12px;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.footer-links .links-row .link-column a {
  color: #ffffff80;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-decoration: none;
}
.links-row.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffff80;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #ffffff50;
  margin: 50px 0 0 0;
}

.app-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  background-color: #f9f9f9;
}
