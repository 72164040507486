/* frontend/src/components/homepage/HomeSection6/HomeSection6.css */
@media (min-width: 300px) {

  .home-section-6 {
    padding: 60px 15px 30px 15px;
    background-color: #000;
    color: #fff;
  }

  .home-section-6 .homeHeadingH2 {
    opacity: 0;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
  }

  .left-column {
    height: 378px;
    margin: 0 10%;
    overflow: hidden;
    width: 80%;
    opacity: 0;
  }
  .right-column {
    display: flex;
    flex-direction: column-reverse;
    gap: 0;
    margin: 0 10%;
    width: 80%;
  }

  .left-column img,
  .right-column img {
    width: 100%;
    height: auto;
  }

  .right-column img {
    opacity: 0;
  }

  .right-column p {
    color: #fff;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: -.15px;
    line-height: 30px;
    margin: 30px 10%;
    opacity: 0;
  }

  /* Animation keyframes */
  @keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(80px); /* Start from below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at the original position */
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* Animation classes */
  .slide-in-up {
    opacity: 0;
    animation: slideInUp 1.5s ease forwards; /* Slide and fade in over 1.5 seconds */
  }

  .fade-in {
    opacity: 0;
    animation: fadeIn 1.5s ease forwards; /* Fade in over 1.5 seconds */
  }

}

@media (min-width: 600px) {


}

@media (min-width: 768px) {

  .left-column {
    height: auto;
  }

  .right-column {
    flex-direction: column;
  }

  .right-column p {
    margin: 30px 0;
    line-height: 32px;
  }

}


@media (min-width: 1024px) {

  .home-section-6 {
    padding: 0px 40px 100px 40px;
    background-color: #000;
    color: #fff;
  }

  .homeHeadingH2 {
    margin-bottom: 50px;
  }

  .homeHeadingH2 h2 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  .homeHeadingH2 p {
    font-size: 1.2em;
    color: #bbb;
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .left-column {
    margin: 0 0 0 10%;
    width: 30%;
  }
  .right-column {
    margin: 0 0 0 10%;
    width: 30%;
  }

  .left-column img,
  .right-column img {
    width: 100%;
    height: auto;
  }

  .right-column p {
    margin-top: 60px;
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.15px;
  }

}


@media (min-width: 1280px) {

  .home-section-6 {
    padding: 0px 40px 100px 40px;
    background-color: #000;
    color: #fff;
  }

  .homeHeadingH2 {
    margin-bottom: 50px;
  }

  .homeHeadingH2 h2 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  .homeHeadingH2 p {
    font-size: 1.2em;
    color: #bbb;
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .left-column {
    margin: 0 0 0 20%;
    width: 20%;
  }
  .right-column {
    margin: 0 0 0 10%;
    width: 30%;
  }

  .left-column img,
  .right-column img {
    width: 100%;
    height: auto;
  }

  .right-column p {
    margin-top: 20px;
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.15px;
  }

}


@media (min-width: 1600px) {



}

