/* frontend/src/components/homepage/HomeGridLines/AnimatedKeyline.css */
.animated-keyline {
  position: absolute;
  width: 1px;
  height: 60px;
  background: #ffffff; /* Adjust color as needed */
  top: 0;
  opacity: 0.6;
  filter: drop-shadow(0 0 20px rgba(255, 255, 0, 1)) drop-shadow(0 0 30px rgba(255, 255, 0, 1));
}

@keyframes animateKeyline {
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 1;
    top: 100vh;
  }
}
