/* Properties Sidebar */


.PositionOptionsPanel .ant-collapse-content-box .ant-row:first-of-type {
    width:100%;
}

.DisplayOptionsPanel .ant-collapse-content-box .ant-row:first-of-type {
    width:100%;
}
.BorderOptionsPanel .ant-collapse-content-box .ant-row:last-of-type {
    width:100%;
}
