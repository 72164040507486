/* frontend/src/components/homepage/HomeSection2/HomeSection2.css */
@media (min-width: 300px) {

  .home-section-2 {
    height: auto;
    padding: 30px 15px;
    background: linear-gradient(180deg, #000 0%, #101010 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  .steps {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10% 0 10%;
    position: relative;
    width: 80%;
  }

  .icon-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:25px;
  }
  .icon-row img {
    margin:0;
  }

  .icon {
    height: auto;
    width: 40%;
    opacity: 0;
    margin:0 0 20px 0;
  }

  .icon-row p {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    width: 50%;
    opacity: 0;
  }


  /* Animation keyframes */
  @keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(80px); /* Start from below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at the original position */
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* Animation classes */
  .slide-in-up {
    opacity: 0;
    animation: slideInUp 1.5s ease forwards; /* Slide and fade in over 1.5 seconds */
  }

  .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease forwards; /* Fade in over 1 second */
  }

  .home-section-2 .homeHeadingH2.slide-in-up,
  .icon.fade-in,
  .icon-row p.fade-in {
    animation-delay: 0.5s; /* Delay for a smoother transition */
  }

}


@media (min-width: 600px) {

  .steps {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0;
    position: relative;
    width: 100%;
  }
  .icon-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 10%;
    gap:25px;
  }
  .icon-row img {
    margin:0;
  }
  .icon {
    height: auto;
    width: 20%;
  }

}


@media (min-width: 768px) {

  .home-section-2 {
    padding: 80px 15px;
  }
  .steps {
    flex-direction: row;
    width: 80%;
    margin: 0 10%;
  }
  .icon-row {
    align-items: start;
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 0;
  }
  .icon {
    width:50%;
  }
  .icon-row p {
    width: 90%;
    margin: 0 10% 0 0;
  }

}


@media (min-width: 1024px) {

  .home-section-2 {
    height: auto;
    padding: 94px 40px 94px 40px;
    background: linear-gradient(180deg, #000 0%, #101010 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  .steps {
    display: flex;
    flex-direction: row;
    gap: 0;
    padding: 0;
    position: relative;
    width: 80%;
  }

  .icon-row {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width:25%;
    padding:0;
  }

  .icon-row .icon {
    width: 140px;
    height: auto;
  }

  .icon-row p {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    width: 80%;
  }

}


@media (min-width: 1280px) {

  .home-section-2 {
    height: auto;
    padding: 94px 40px 94px 40px;
    background: linear-gradient(180deg, #000 0%, #101010 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  .steps {
    display: flex;
    flex-direction: row;
    gap: 25px;
    padding: 30px 10% 0 10%;
    position: relative;
    width: 80%;
  }

  .icon-row {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width:25%;
  }

  .icon {
    width: 140px;
    height: auto;
  }

  .icon-row p {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    width: 80%;
  }

}


@media (min-width: 1600px) {


}