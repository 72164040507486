/* frontend/src/components/homepage/HomeSection5/HomeSection5.css */
@media (min-width: 300px) {

  .home-section-5 {
    background-color: #000;
    color: #fff;
    display: flex;
    height: auto;
    overflow: hidden;
    position: relative;
    width: calc(100% - 30px);
    padding: 0px 15px;
  }

  .home-section-5 .homeHeadingH2 {
    opacity: 0;
  }

  .section-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .content-wrapper {
    display: flex;
    height: 100%;
    position: relative;
    flex-direction: row;
    width: 100%;
  }

  .left-content {
    display: none;
  }
  .left-content img {
    opacity: 0;
  }

  .keylines {
    display: none;
  }

  .right-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    justify-content: flex-start;
    margin: 0 10% 0 10%;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 80%;
    z-index: 10;
  }

  .content-div {
    width: 100%;
    opacity: 1;
    /*transform: translateY(50px);*/
    transition: opacity 0.5s ease, transform 0.5s ease;
    cursor: pointer; /* Make it clear that divs are clickable */
  }

  .content-div.active {
    opacity: 1;
    /*transform: translateY(0);*/
  }
  .content-div p {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -.15px;
    line-height: 26px;
  }

  .rightContentCard {
    padding: 15px;
    border-radius: 5px;
    border: 0.833px #C8C8C8;
    background: #F6F8FD;
    color: #00152D;
    display: flex;
    gap: 5px;
    height: auto;
    flex-direction: column;
  }
  .rightContentCard h4 {
    margin: 0;
    font-size: 18px;
  }

  .rightContentCard p {
    margin:0;
    font-size:15px;
    line-height: 20px;
    font-weight:400;
    opacity:0.7;
    color: #00152D;
  }

  .right-content .content-div + .content-div {
    margin-top: 0px;
  }

  /* Animation keyframes */
  @keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(80px); /* Start from below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at the original position */
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* Animation classes */
  .slide-in-up {
    opacity: 0;
    animation: slideInUp 1.5s ease forwards; /* Slide and fade in over 1.5 seconds */
  }

  .fade-in {
    opacity: 0;
    animation: fadeIn 1.5s ease forwards; /* Fade in over 1.5 seconds */
  }

}


@media (min-width: 600px) {

  .home-section-5 {
    padding: 40px 15px;
  }

}


@media (min-width: 768px) {

  .home-section-5 {
    padding: 80px 15px;
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .left-content {
    display: flex;
    width: 40%;
    margin: 0 0 0 10%;
  }
  .left-content img {
    width:100%;
  }

  .right-content {
    width: 30%;
  }
  .content-div {
    opacity: 0.5;
  }

}


@media (min-width: 1024px) {

  .home-section-5 {
    background-color: #000;
    color: #fff;
    display: flex;
    height: auto;
    overflow: hidden;
    position: relative;
    width: calc(100% - 80px);
    padding: 50px 40px 140px 40px;
  }

  .section-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .content-wrapper {
    display: flex;
    height: 100%;
    position: relative;
    flex-direction: row!important;
    width: 100%;
  }

  .left-content {
    display: flex;
    position: relative;
    top: 0;
    width: 30%;
    margin: 0 10% 0 10%;
    height: auto;
    flex-direction: row;
    align-items: flex-start;
  }

  .left-content img {
    width: 100%;
    height: auto;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .keylines {
    display: flex;
    flex-direction: column;
    gap: 50px;
    position: relative;
    right: -33%;
    top: 329px;
    transform: translateY(-50%);
    height: auto;
  }

  .keyline {
    background: #ffffff80;
    cursor: pointer;
    height: 185px;
    position: relative;
    width: 2px;
  }

  .keyline.active {
    background: rgba(255, 255, 255, 1);
  }

  .keyline.active::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    top: 0;
    left: 0;
    transition: height 0.5s ease;
  }

  .right-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin: 0 10%;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 30%;
    z-index: 10;
    gap:30px;
  }

  .content-div {
    width: 100%;
    opacity: 0.25;
    transition: opacity 0.5s ease, transform 0.5s ease;
    cursor: pointer; 
  }

  .content-div.active {
    opacity: 1;
  }
  .content-div p {
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px; 
    letter-spacing: -0.15px;
  }

  .rightContentCard {
    padding: 15px;
    border-radius: 5px;
    border: 0.833px #C8C8C8;
    background: #F6F8FD;
    color: #00152D;
    display: flex;
    gap: 10px;
    height: auto;
    flex-direction: column;
  }
  .rightContentCard h4 {
    margin: 0;
    font-size: 18px;
  }

  .rightContentCard p {
    margin:0;
    font-size:15px;
    line-height: 20px;
    font-weight:400;
    opacity:0.7;
    color: #00152D;
  }

  .right-content .content-div + .content-div {
    margin-top: 0px;
  }

}


@media (min-width: 1280px) {

  .home-section-5 {
    background-color: #000;
    color: #fff;
    display: flex;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: calc(100% - 80px);
    padding: 140px 40px;
  }

  .section-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .content-wrapper {
    display: flex;
    height: 100%;
    position: relative;
    flex-direction: row;
    width: 100%;
  }

  .left-content {
    display: flex;
    position: relative;
    top: 0;
    width: calc(50% - 30%);
    margin: 0 10% 0 20%;
    height: 607px;
    flex-direction: row;
    align-items: flex-start;
  }

  .left-content img {
    width: 100%;
    height: auto;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .keylines {
    display: flex;
    flex-direction: column;
    gap: 50px;
    position: relative;
    right: -27%;
    top: 329px;
    transform: translateY(-50%);
    height: auto;
  }

  .keyline {
    background: #ffffff80;
    cursor: pointer;
    height: 185px;
    position: relative;
    width: 2px;
  }

  .keyline.active {
    background: rgba(255, 255, 255, 1);
  }

  .keyline.active::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    top: 0;
    left: 0;
    transition: height 0.5s ease;
  }

  .right-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin: 0 20% 0 0%;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 30%;
    z-index: 10;
    gap:30px;
  }

  .content-div {
    width: 100%;
    opacity: 0.25;
    /*transform: translateY(50px);*/
    transition: opacity 0.5s ease, transform 0.5s ease;
    cursor: pointer; 
    opacity: 0;
  }

  .content-div.active {
    opacity: 1;
    /*transform: translateY(0);*/
  }
  .content-div p {
    color: #FFF;
    font-size: 21px;
    font-weight: 700;
    line-height: 26px; /* 108.333% */
    letter-spacing: -0.15px;
  }

  .rightContentCard {
    padding: 15px;
    border-radius: 5px;
    border: 0.833px #C8C8C8;
    background: #F6F8FD;
    color: #00152D;
    display: flex;
    gap: 10px;
    height: auto;
    flex-direction: column;
  }
  .rightContentCard h4 {
    margin: 0;
    font-size: 18px;
  }

  .rightContentCard p {
    margin:0;
    font-size:15px;
    line-height: 20px;
    font-weight:400;
    opacity:0.7;
    color: #00152D;
  }

  .right-content .content-div + .content-div {
    margin-top: 0px;
  }

}


@media (min-width: 1600px) {



}

