/* frontend/src/components/general/VerticalScrollIndicator/VerticalScrollIndicator.css */
.scroll-indicator-wrapper {
  position: absolute;
  right: calc(20% + 31px);
  height: 572px;
  top: 55%;
}

.scroll-indicator {
  width: 2px;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: relative;
}

.indicator {
  width: 2px;
  height: 0;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0;
  animation: scrollIndicatorAnimation 3s infinite ease-in-out;
}

@keyframes scrollIndicatorAnimation {
  0% {
    height: 0;
    top: 0;
  }
  50% {
    height: 100%;
    top: 0;
  }
  100% {
    height: 0;
    top: 100%;
  }
}
