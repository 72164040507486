/* frontend/src/components/homepage/HomeTitleReveal/HomeTitleReveal.css */
@media (min-width: 300px) {

  .home-title-reveal {
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    overflow: hidden;
  }

  .title-reveal-video {
    width: 90%;
    height: auto;
  }

}

@media (min-width: 600px) {



}


@media (min-width: 768px) {

  .home-title-reveal {
    height: 480px;
  }

}


@media (min-width: 1024px) {

  .home-title-reveal {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    overflow: hidden;
  }

  .title-reveal-video {
    width: 60%;
    height: auto;
  }

}


@media (min-width: 1280px) {

  .home-title-reveal {
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    overflow: hidden;
  }

  .title-reveal-video {
    width: 60%;
    height: auto;
  }

}


@media (min-width: 1600px) {



}
