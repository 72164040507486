/* frontend/src/components/homepage/hero/HeroMain.css */
@media (min-width: 300px) {

  .hero-main-wrapper {
    position: relative;
    background: linear-gradient(122deg, #FEDFAC -20.72%, #EBD3FE 123.45%);
    text-align: center;
    overflow: hidden;
    /*height: 722px;*/
    height: 0;
    padding: 90px 0 0 0;
    animation: wrapperExpand 1.5s ease-in-out forwards; /* Expand height */
  }

  .hero-main-contents {
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
  }

  .hero-main-contents h1, .hero-main-contents p { 
    opacity: 0;
    animation: fadeInText 1s ease-in-out 1.5s forwards; /* Fade in after the wrapper expands */
  }

  .hero-main-contents h1 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    color: #14191E;
    text-align: left;
    padding: 0 10%;
    margin: 0;
    z-index: 200;
    position: relative;
  }

  .hero-main-contents p {
    padding: 0 10%;
    text-align: left;
    z-index: 200;
    position: relative;
    color: #00152d;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 28px;
    margin: 0 0 20px 0;
  }

  .hero-images {
    position: relative;
    display: inline-block;
    opacity: 0;
    animation: appScreenSlideIn 1.5s ease-out 1.5s forwards; /* Animate in the app-screen after the wrapper expands */
  }

  .app-screen {
    position: relative;
    width: 90%;
    z-index: 1;
    animation: appScreenSlideIn 1.5s ease-out 1.5s forwards; /* Animate in the app-screen after the wrapper expands */
  }

  .shape {
    position: absolute;
    opacity: 0; /* Start with shapes hidden */
    animation: fadeInShapes 1s ease-in-out 2s forwards; /* Fade in the shapes */
    transform: translate3d(var(--offset-x, 0), var(--offset-y, 0), 0);
    transition: transform 0.2s ease-out;
  }

  .shape1 {
    left: -20px;
    top: -5px;
    z-index: 0;
    width: 80px;
  }

  .shape2 {
    bottom: 35px;
    right: -35px;
    z-index: 100;
    width: 90px;
  }

  @keyframes wrapperExpand {
    0% {
      height: 0;
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      height: 324px; /* Or whatever the full height is */
      opacity: 1;
    }
  }

  @keyframes appScreenSlideIn {
    0% {
      transform: translateY(324px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeInText {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInShapes {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

}

@media (min-width: 600px) {

  .hero-main-wrapper {
    min-height: 380px;
  }

  @keyframes wrapperExpand {
    0% {
      height: 0;
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      height: 380px; /* Or whatever the full height is */
      opacity: 1;
    }
  }

  @keyframes appScreenSlideIn {
    0% {
      transform: translateY(380px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

}


@media (min-width: 768px) {

  .hero-main-wrapper {
    max-height: 550px;
    min-height: 458px;
  }

  .shape1 {
    left: 20px;
    top: -65px;
    width: 100px;
    z-index: 0;
  }
  .shape2 {
    bottom: 115px;
    right: 0px;
    width: 115px;
    z-index: 100;
  }

  @keyframes wrapperExpand {
    0% {
      height: 0;
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      height: 471px; /* Or whatever the full height is */
      opacity: 1;
    }
  }

  @keyframes appScreenSlideIn {
    0% {
      transform: translateY(324px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}


@media (min-width: 1024px) {
  .hero-main-wrapper {
    max-height: 521px;
  }
  .hero-main-contents h1 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 5px;
  }
  .hero-main-contents p {
    font-size: 18px;
    line-height: 30px;
  }

  .shape1 {
    left: 10px;
    top: -75px;
    width: 160px;
    z-index: 0;
  }
  .shape2 {
    bottom: 105px;
    right: -35px;
    width: 180px;
    z-index: 100;
  }

  @keyframes wrapperExpand {
    0% {
      height: 0;
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      height: 521px; /* Or whatever the full height is */
      opacity: 1;
    }
  }

  @keyframes appScreenSlideIn {
    0% {
      transform: translateY(400px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}


@media (min-width: 1280px) {

  .hero-main-wrapper {
    position: relative;
    background: linear-gradient(122deg, #FEDFAC -20.72%, #EBD3FE 123.45%);
    text-align: center;
    overflow: hidden;
    height: 0; /* Start with a height of 0 */
    padding: 80px 0 0 0;
    animation: wrapperExpand 1.5s ease-in-out forwards; /* Expand height */
    max-height: 620px;
    min-height: 0;
  }

  .hero-main-contents {
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
  }

  .hero-main-contents h1, .hero-main-contents p { 
    opacity: 0;
    animation: fadeInText 1s ease-in-out 1.5s forwards; /* Fade in after the wrapper expands */
  }

  .hero-main-contents h1 {
    font-size: 50px;
    line-height: 55px;
    font-weight: 700;
    color: #14191E;
    text-align: left;
    padding: 0 10%;
    margin: 0;
    z-index: 200;
    position: relative;
  }

  .hero-main-contents p {
    padding: 0 10%;
    text-align: left;
    z-index: 200;
    position: relative;
    color: #00152d;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 28px;
    margin: 0 0 20px 0;
  }

  .hero-images {
    position: relative;
    display: inline-block;
    opacity: 0;
    width: 80%;
    padding: 0;
    animation: appScreenSlideIn 1.5s ease-out 1.5s forwards; /* Animate in the app-screen after the wrapper expands */
  }

  .app-screen {
    position: relative;
    z-index: 1;
    width: 100%;
    animation: appScreenSlideIn 1.5s ease-out 1.5s forwards; /* Animate in the app-screen after the wrapper expands */
  }

  .shape {
    position: absolute;
    opacity: 0; /* Start with shapes hidden */
    animation: fadeInShapes 1s ease-in-out 2s forwards; /* Fade in the shapes */
    transform: translate3d(var(--offset-x, 0), var(--offset-y, 0), 0);
    transition: transform 0.2s ease-out;
  }

  .shape1 {
    top: -77px;
    left: 80px;
    width: auto;
    z-index: 0;
  }

  .shape2 {
    bottom: 121px;
    right: 86px;
    width: auto;
    z-index: 100;
  }

  @keyframes wrapperExpand {
    0% {
      height: 0;
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      height: 802px; /* Or whatever the full height is */
      opacity: 1;
    }
  }

  @keyframes appScreenSlideIn {
    0% {
      transform: translateY(100vh);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeInText {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInShapes {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

}


@media (min-width: 1440px) {
  .hero-main-wrapper {
    min-height: 710px;
    max-height: 800px;
  }
}