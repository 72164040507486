/* MyJiraTicketSidebar.css */

.JiraSidebaruWrap {
	width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 0 0 5px 5px;
}

/* select project */
.JiraSidebaruWrap .ant-select {
	width: 100%;
  height: 28px;
}
.JiraSidebaruWrap .ant-select .ant-select-selector {
	border: none;
  border-bottom: 1px solid #cecece;
  background: #f1f1f1;
  border-radius: 0;
}
.JiraSidebaruWrap .ant-select .ant-select-selector .ant-select-selection-placeholder {
	font-size: 12px;
	color: #000;
	font-weight: 400;
}
.JiraSidebaruWrap .ant-select .ant-select-selector .ant-select-selection-item {
	font-size: 12px;
	color: #000;
	font-weight: 500;
}
/* Jira board and other buttons */
.JiraSidebaruWrap .JiraViewButtons {
	display: flex;
  justify-content: space-between;
  margin: 10px;
}
.JiraSidebaruWrap .JiraViewButtons .ant-btn {
	font-size: 12px;
}

/* Ticket list panel */
.JiraSidebaruWrap .ant-list {
	height: 100%;
  overflow: scroll;
}
.JiraSidebaruWrap .ant-list .ant-list-items {
	display: flex;
  flex-direction: column;
  gap: 10px;
}
.JiraSidebaruWrap .ant-list .ant-list-item {
  margin: 0 10px;
  border-radius: 5px;
  border: 1px solid #dedede;
  background: #f1f1f1;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.JiraSidebaruWrap .ant-list .ant-list-item:hover {
	background:#fff;
	transition: opacity 0.2s ease;
}
.JiraSidebaruWrap .ant-list .ant-list-item .ant-list-item-meta {
  margin-block-end: 10px;
}
.JiraSidebaruWrap .ant-list .ant-list-item .ant-list-item-meta-title {
	font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}
.JiraSidebaruWrap .ant-list .ant-list-item .ant-list-item-meta-description {
	font-size: 14px;
  line-height: 20px;
  margin:0;
}
.JiraSidebaruWrap .ant-list .ant-list-item .ant-btn {
	height: 30px;
  width: auto;
  display: block;
  min-width: auto;
  padding: 0 10px;
  margin: 0;
}

