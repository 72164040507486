/* MediaSidebar.css */

.mediaSidebar {
  background-color: #fff;
  border-radius: 10px;
  margin: 10px;
  margin: 0 0 10px 10px !important;
  min-width: 220px;
  padding: 0;
  width: 220px;
  overflow-y: scroll;
}

.sub-folder {
  border-bottom: 1px solid #e5e4e4;
  column-gap: 10px;
  display: flex;
  flex-direction: row;
  height: 31px;
  transition: background-color .2s ease;
  color: #00152d;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-overflow: ellipsis;
  width: 100%;
  padding: 7px 10px;
  box-sizing: border-box;
  align-content: center;
  cursor: pointer;
}

.sub-folder:hover {
  background-color: #f0f0f0;
}

.main-folder {
  display: none;
}

.mediaWrapper div.mediaSidebar > div:nth-child(3) {
  display: block !important;
}

.mediaWrapper .MediaContents .mediaSidebar .main-folder:nth-of-type(1) {
  display: block !important;
}
.mediaWrapper .MediaContents .mediaSidebar div:nth-of-type(3) {
  display: block !important;
}

.all-files {
  display: block;
  cursor: pointer;
}
.all-files strong{
  align-content: center;
  border-bottom: 1px solid #e5e4e4;
  box-sizing: border-box;
  color: #00152d;
  column-gap: 10px;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 31px;
  letter-spacing: 0;
  line-height: normal;
  padding: 7px 10px;
  text-overflow: ellipsis;
  transition: background-color .2s ease;
  width: 100%;
}
.sub-folder:hover strong {
  background-color: #f0f0f0;
}

.all-files.active, .sub-folder.active {
  background-color: #e2ecff;
  font-weight: 600!important;
}


.mediaSidebarHeading {
  background-color: #f1f1f1;
  border-bottom: 1px solid #cecece;
  border-radius: 5px 5px 0 0;
  display: flex;
  height: 35px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0px;
}

.mediaSidebarHeading h4 {
  border: none;
  color: #767676;
  font-size: 12px;
  font-weight: 700;
  padding:0 10px;
  margin:0;
  width:100%;
}