/* CodeEditor.css old */

.interface-wrapper {
  display: flex;
  height: 100vh;
  position: relative;
}

/* left sidebar */
.interface-left-sidebar {
  background-color: #ccc;
  width: 240px;
  transition: width 0.3s ease;
  display: flex;
  align-items: flex-start;
}
.interface-left-sidebar.collapsed {
  width: 40px;
}
.sidebar-wrap-contents {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding: 8px;
}

.interface-left-container {
  flex-grow: 1;
  min-width: 200px;
  max-width: calc(100% - 100px);
  overflow: auto;
  transition: width 0.3s ease;
  position: relative;
}
.adjust-container-width {
  width: 30px;
  height: 100%;
  cursor: col-resize;
}

.interface-right-container {
  flex-grow: 1;
  min-width: 200px;
  max-width: calc(100% - 100px);
  overflow: auto;
  transition: width 0.3s ease;
  position: relative;
}

.interface-right-sidebar {
  background-color: #ccc;
  width: 240px;
  transition: width 0.3s ease;
}

.interface-right-sidebar.collapsed {
  width: 40px;
}

.collapsed {
  overflow: hidden;
}

.toggle-sidebar-button {
  background-color: #ccc;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  margin: 0;
  width: 100%;
  text-align: left;
}
.collapsed .toggle-sidebar-button {
  padding:8px;
}

.toggle-sidebar-button:focus {
  outline: none;
}

.toggle-left-sidebar-button {
  left: 10px;
}

.toggle-right-sidebar-button {
  right: 10px;
}

/* Common resizing handle style for both left and right containers */
.resizing-edge {
  position: absolute;
  top: 0;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  z-index: 1;
}

/* Style for the left resizing handle */
.resizing-edge.left {
  left: 0;
}

/* Style for the right resizing handle */
.resizing-edge.right {
  right: 0;
}
