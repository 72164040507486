.mediaUploadButton {
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 0;
  display: flex;
  height: 35px;
  border: none;
  border-bottom: 1px solid #CECECD;
  border-left: 1px solid #cecece;
  font-size: 13px;
  padding: 10px;
}




.mediaContainer .ant-list-split {
  width:100%;
}

.mediaContainer .ant-list-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
}

.mediaContainer .ant-list .ant-list-item {
  align-content: space-between;
  background: #f1f1f1;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  width: calc(25% - 12px);
}

.mediaContainer .ant-list-item-meta {
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
  height: auto;
}
.mediaContainer .ant-list-item-meta-avatar {
  height: 120px;
  margin: 0;
  width: 100%;
  margin-inline-end: 0 !important;
}
.mediaContainer .ant-list-item-meta-avatar img {
  width: 100%!important;
  height: 100%!important;
  object-fit: cover!important;
  border-radius: 5px;
}
.mediaContainer .ant-list-item-meta-content {
  width: 100%!important;
  height: auto;
  display: flex;
  flex-direction: column;
}
.mediaContainer .ant-list-item-meta-title {
  font-size: 14px !important;
  font-weight: 600;
  margin: 10px 0 5px !important;
  max-height:44px;
  min-height: 22px;
  height:auto;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mediaContainer .ant-list-item-meta-description {
  font-size: 12px!important;
}
.mediaContainer .ant-list-item-action {
  width: 100%;
  display: flex;
  margin: 0;
}

.mediaContainer .ant-list-item-action {
  display: flex;
  margin: 10px 0 0 0;
  width: 100%;
  margin-inline-start: 0 !important;
  justify-content: space-between;
}
.mediaContainer .ant-list-item-action li {
  padding: 0 !important;
  background: #00000010;
  border-radius: 5px;
}
.mediaContainer .ant-list-item-action li:hover {
  background: #ffffff50;
}
