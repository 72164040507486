/* frontend/src/components/homepage/HomeSection3/HomeSection3.css */
@media (min-width: 300px) {

  .home-section-3 {
    height: auto;
    padding: 60px 15px;
    background-color: #101010;
    color: #fff;
    display: flex;
    flex-direction: column;
  }
  .home-section-3 .homeHeadingH2 {
    opacity: 0;
  }
  .homeHeadingH2 h2 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  .homeHeadingH2 p {
    font-size: 1.2em;
    color: #bbb;
  }

  .video-container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    position: relative;
  }

  .demo-video {
    border-radius: 10px;
    margin: 0;
    width: 100%;
    opacity: 0;
  }

  .home-section-3 .scroll-indicator-wrapper {
    height: 150px;
    left: 0%;
    right: auto;
    top: 120%;
  }

  /* Animation keyframes */
  @keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(80px); /* Start from below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at the original position */
    }
  }

  /* Animation classes */
  .slide-in-up {
    opacity: 0;
    animation: slideInUp 1.5s ease forwards; /* Slide and fade in over 1.5 seconds */
  }

  .slide-in-up-video {
    opacity: 0;
    animation: slideInUp 1.5s ease forwards; /* Slide and fade in over 1.5 seconds */
    animation-delay: 0.5s; /* Delay for a smoother transition */
  }

}


@media (min-width: 600px) {



}


@media (min-width: 768px) {

  .home-section-3 {
    padding: 80px 15px;
  }

  .demo-video {
    width: 80%;
  }

  .home-section-3 .scroll-indicator-wrapper {
    height: 308px;
    left: 5%;
    right: auto;
    top: 60%;
  }
}


@media (min-width: 1024px) {

  .home-section-3 {
    height: auto;
    padding: 94px 40px 140px 40px;
    background-color: #101010;
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  .homeHeadingH2 h2 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  .homeHeadingH2 p {
    font-size: 1.2em;
    color: #bbb;
  }

  .video-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
  }

  .demo-video {
    width: 80%;
    margin: 0;
    border-radius: 10px;
  }

  .home-section-3 .scroll-indicator-wrapper {
    top: 60%;
    right: auto;
    left: 5%;
    height: 433px;
  }

}


@media (min-width: 1280px) {

  .home-section-3 {
    height: auto;
    padding: 94px 40px 140px 40px;
    background-color: #101010;
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  .homeHeadingH2 h2 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  .homeHeadingH2 p {
    font-size: 1.2em;
    color: #bbb;
  }

  .video-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
  }

  .demo-video {
    width: 70%;
    margin: 0 0 0 10%;
    border-radius: 10px;
  }

  .home-section-3 .scroll-indicator-wrapper {
    top: 60%;
    right: auto;
    left: 5%;
    height: 433px;
  }

}


@media (min-width: 1600px) {


}