/* frontend/src/components/homepage/HomeSection1/HomeSection1.css */
@media (min-width: 300px) {

  .home-section-1 {
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: #000;
    height: auto;
    padding: 40px 15px;
  }

  .content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:0;
  }
  .content-wrapper .content {
    padding: 0;
    width: 100%;
  }
  .inner-content-row {
    width:100%;
  }

  .content h2 {
    font-size: 3em;
    color: #fff;
    margin-bottom: 20px;
  }

  .content p {
    color: #fff;
    margin-bottom: 20px;
  }

  .login-image-container {
    position: relative;
    z-index: 10;
    padding: 0 10%;
    width: calc(100% - 20%);
  }

  .login-image {
    width: 100%;
    height:auto;
    position: relative;
    z-index: 3;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    opacity: 0;
  }

  .browser-icons {
    display: flex;
    gap: 0;
    left: 10%;
    position: absolute;
    top: 0%;
    width: calc(100% - 20%);
    height: 100%;
  }

  .browser-icon {
    width: 100px;
    height: 100px;
    z-index: 1;
    position: absolute;
    opacity: 0;
  }

  .browser-icon:nth-child(1) {
    left: -46px;
    top: -30px;
    z-index: 3;
    width: 80px;
    height: auto;
  }

  .browser-icon:nth-child(2) {
    height: auto;
    left: -30px;
    top: 150px;
    width: 50px;
    z-index: 2;
  }

  .browser-icon:nth-child(3) {
    height: auto;
    right: -35px;
    top: 5px;
    width: 40px;
  }
  .browser-icon:nth-child(4) {
    bottom: 50px;
    height: auto;
    right: -63px;
    width: 35px;
  }
  .browser-icon:nth-child(5) {
    bottom: -44px;
    height: auto;
    right: 20px;
    width: 90px;
    z-index: 3;
  }

  .side-text {
    color: #fff;
    font-size: 21px;
    font-weight: 600;
    line-height: 28px;
    position: relative;
    margin: 20px 0 0 10%;
    text-align: left;
    width: 80%;
    opacity: 0;
  }

  .scroll-indicator-wrapper {
    height: 200px;
    top: 405px;
    right: 10%;
  }

  /* Animation keyframes */
  @keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(80px); /* Start from below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at the original position */
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* Animation classes */
  .slide-in-up {
    opacity: 0;
    animation: slideInUp 1.5s ease forwards; /* Slide and fade in over 1 second */
  }

  .home-section-1 .homeHeadingH2.slide-in-up,
  .login-image.slide-in-up {
    animation-delay: 0.5s; /* Add delay for a smoother transition */
  }

  .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
  }

  .side-text.slide-in-up {
    animation-delay: 1.5s; /* Delay for the side text */
  }

}


@media (min-width: 600px) {


}


@media (min-width: 768px) {

  .home-section-1 {
    padding: 100px 15px;
  }

  .inner-content-row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .login-image-container {
    padding: 0 0% 0 10%;
    position: relative;
    width: 40%;
    z-index: 10;
  }
  .browser-icons {
    left: 20%;
    width: 80%;
  }

  .side-text {
    width: 30%;
    margin: 0 0 0 10%;
    display: flex;
    align-items: center;
  }

  .scroll-indicator-wrapper {
    height: 412px;
    right: 10%;
    top: 180px;
  }

}


@media (min-width: 1024px) {

  .home-section-1 {
    padding: 100px 40px;
  }

  .scroll-indicator-wrapper {
    height: 387px;
    top: 300px;
  }

}


@media (min-width: 1280px) {

  .home-section-1 {
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: #000;
    height: auto;
    padding: 140px 40px;
  }

  .content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:0;
  }
  .content-wrapper .content {
    padding: 0;
    width: 100%;
  }
  .inner-content-row {
    width:100%;
  }

  .content h2 {
    font-size: 3em;
    color: #fff;
    margin-bottom: 20px;
  }

  .content p {
    color: #fff;
    margin-bottom: 20px;
  }

  .login-image-container {
    position: relative;
    z-index: 10;
    padding: 0 40% 0 20%;
    width:40%;
  }

  .login-image {
    width: 100%;
    height:auto;
    position: relative;
    z-index: 3;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  }

  .side-text {
    position: absolute;
    top: 330px;
    right: 10%;
    transform: translateY(-50%);
    color: #fff;
    text-align: left;
    width: 20%;
    font-size:24px;
    line-height: 32px;
    font-weight: 600;
  }

  .browser-icons {
    display: flex;
    gap: 0;
    left: 20%;
    position: absolute;
    top: 0%;
    width: 40%;
    margin: 0 40% 0 0%;
    height: 100%;
  }

  .browser-icon {
    width: 100px;
    height: 100px;
    z-index: 1;
    position: absolute;
  }

  .browser-icon:nth-child(1) {
    left: -76px;
    top: -20px;
    z-index: 3;
  }

  .browser-icon:nth-child(2) {
    z-index: 2;
    width: 70px;
    height: auto;
    top: 290px;
    left: -50px;
  }

  .browser-icon:nth-child(3) {
    right: -40px;
    height: auto;
    width: 70px;
    top: 30px;
  }
  .browser-icon:nth-child(4) {
    bottom: 50px;
    right: -120px;
    width: 55px;
    height: auto;
  }
  .browser-icon:nth-child(5) {
    width: 144px;
    bottom: -64px;
    right: 50px;
    z-index: 3;
    height: auto;
  }
  .scroll-indicator-wrapper {
    height:340px;
    top:485px;
  }
}


@media (min-width: 1600px) {


}